import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Openingstijden = "Openingstijden",
        Table1 = "Table1",
        Column2 = "Column2",
        dag = "dag",
        Column4 = "Column4",
        open_tijd = "open_tijd",
        Column6 = "Column6",
        gesloten_tijd = "gesloten_tijd",
        Column8 = "Column8",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        cToevoegen = "cToevoegen",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get dag(): FormControl {
        return this.getTableChildFormControl(MgControlName.dag);
    }

    get open_tijd(): FormControl {
        return this.getTableChildFormControl(MgControlName.open_tijd);
    }

    get gesloten_tijd(): FormControl {
        return this.getTableChildFormControl(MgControlName.gesloten_tijd);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}