import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    ChangePWD = "ChangePWD",
        lOudWW = "lOudWW",
        cPWDOLD = "cPWDOLD",
        lWW1 = "lWW1",
        cPWD1 = "cPWD1",
        lWW2 = "lWW2",
        cPWD2 = "cPWD2",
        cChangePWD = "cChangePWD",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cPWDOLD(): FormControl {
        return this.fg.controls[MgControlName.cPWDOLD] as FormControl;
    }

    get cPWD1(): FormControl {
        return this.fg.controls[MgControlName.cPWD1] as FormControl;
    }

    get cPWD2(): FormControl {
        return this.fg.controls[MgControlName.cPWD2] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}