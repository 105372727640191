import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    FileTransfer = "FileTransfer",
        Blob64AtServer = "Blob64AtServer",
        vfilename = "vfilename",
        vOpenOrDownload = "vOpenOrDownload",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Blob64AtServer(): FormControl {
        return this.fg.controls[MgControlName.Blob64AtServer] as FormControl;
    }

    get vfilename(): FormControl {
        return this.fg.controls[MgControlName.vfilename] as FormControl;
    }

    get vOpenOrDownload(): FormControl {
        return this.fg.controls[MgControlName.vOpenOrDownload] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}