import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Gebruikers = "Gebruikers",
        Table1 = "Table1",
        ColAchternaam = "ColAchternaam",
        achternaam = "achternaam",
        ColVoornaam = "ColVoornaam",
        voornaam = "voornaam",
        ColEmail = "ColEmail",
        email = "email",
        ColTelefoon = "ColTelefoon",
        cTelefoon = "cTelefoon",
        ColRole = "ColRole",
        cRole = "cRole",
        ColBTN = "ColBTN",
        ColBTN2 = "ColBTN2",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        cVerwijderen = "cVerwijderen",
        cToevoegen = "cToevoegen",
        pb_Toevoegen = "pb_Toevoegen",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'ColAchternaam',
        'ColVoornaam',
        'ColEmail',
        'ColTelefoon',
        'ColRole',
        'ColBTN',
        'ColBTN2',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get achternaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.achternaam);
    }

    get voornaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.voornaam);
    }

    get email(): FormControl {
        return this.getTableChildFormControl(MgControlName.email);
    }

    get cTelefoon(): FormControl {
        return this.getTableChildFormControl(MgControlName.cTelefoon);
    }

    get cRole(): FormControl {
        return this.getTableChildFormControl(MgControlName.cRole);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}