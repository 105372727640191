import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    NieuweGebruiker = "NieuweGebruiker",
        Label10 = "Label10",
        voornaam = "voornaam",
        Label11 = "Label11",
        achternaam = "achternaam",
        Label13 = "Label13",
        email = "email",
        Label14 = "Label14",
        telefoon = "telefoon",
        Label15 = "Label15",
        role = "role",
        Opslaan = "Opslaan",
        Annuleer = "Annuleer",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get voornaam(): FormControl {
        return this.fg.controls[MgControlName.voornaam] as FormControl;
    }

    get achternaam(): FormControl {
        return this.fg.controls[MgControlName.achternaam] as FormControl;
    }

    get email(): FormControl {
        return this.fg.controls[MgControlName.email] as FormControl;
    }

    get telefoon(): FormControl {
        return this.fg.controls[MgControlName.telefoon] as FormControl;
    }

    get role(): FormControl {
        return this.fg.controls[MgControlName.role] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}