<div
	novalidate
	[formGroup]="screenFormGroup"
	[magic]="mgc.AanvraagBeheer"
>
	<div class="container-fluid">
		<div class="row">
			<div class="col-6">
				<mat-form-field>
					<input
						matInput
						[magic]="mgc.cZoekveld"
						[formControlName]="mgc.cZoekveld"
						mgFormat
					>
					<mgError [magic]=mgc.cZoekveld></mgError>
					<button
						mat-button
						matSuffix
						mat-icon-button
						[magic]="mgc.cZoek"
					>
						<mat-icon>search</mat-icon>
					</button>
				</mat-form-field>
			</div>

			<div class="col mt-2">
				<button
					mat-raised-button
					color="primary"
					[magic]="mgc.pb_refresh"
				>
					<mat-icon>sync</mat-icon> Vernieuwen
				</button>
			</div>
		</div>
	</div>
	<div>
		<mat-card>
			<magic-subform [magic]="mgc.cAanvragen">
			</magic-subform>
		</mat-card>
	</div>
</div>
