<div novalidate [formGroup]="screenFormGroup">
  <div [magic]="mgc.Aanvraagformulier">

    <div class="row-btn">

      <button
        class="btnForm"
        mat-raised-button
        color="primary"
        [magic]="mgc.cLoginForm"
        [style.visibility]="mg.getVisible(mgc.cLoginForm)"
      >
        Login
      </button>
    </div>

    <div class="row categoryBlock">
      <div class="col-lg-12">
        <h3>Aanmelding voedselpakket</h3>
      </div>
    </div>

    <!--Gegevens afnemer-->
    <div class="row categoryBlock" id="target">
      <div class="col-lg-4">
        <h5>Gegevens afnemer</h5>

        <!--Voornaam-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lVoornaam) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cVoornaam"
            [formControlName]="mgc.cVoornaam"
            mgformat
            required
          >
          <mgError [magic]=mgc.cVoornaam></mgError>
        </mat-form-field>

        <!--Achternaam-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lAchternaam) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cAchternaam"
            [formControlName]="mgc.cAchternaam"
            mgformat
            required
          />
          <mgError [magic]=mgc.cAchternaam></mgError>
        </mat-form-field>


        <!--Geboortedatum-->
        <mat-form-field   [magic]="mgc.cGeboorteDatum" [eventsOnly]=true>
          <mat-label>
            {{ mg.getText(mgc.lGeboorteDatum) }}</mat-label>
          <input
            matInput
            [matDatepicker]="cGeboorteDatum"
            [magic]="mgc.cGeboorteDatum"
            [formControlName]="mgc.cGeboorteDatum"
            [style.visibility]="mg.getVisible(mgc.cGeboorteDatum)"
            mgFormat
          />
          <mat-datepicker-toggle
            matSuffix [for]="cGeboorteDatum"
            *ngIf="mg.checkIsReadOnly(mgc.cGeboorteDatum)">
          </mat-datepicker-toggle>
          <mat-datepicker #cGeboorteDatum></mat-datepicker>
        </mat-form-field>

        <!--Geslacht-->
        <mat-form-field>
          <mat-label>Geslacht</mat-label>
          <mat-select
            [magic]="mgc.cGeslacht"
            [formControlName]="mgc.cGeslacht"
          >
            <mat-option
              *ngFor="let o of mg.getItemListValues(mgc.cGeslacht);"
              [value]="o.index">
              {{o.displayValue}}
            </mat-option>
          </mat-select>
          <mgError [magic]=mgc.cGeslacht></mgError>
        </mat-form-field>

        <!--Adres-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lAdres) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cAdresAanvrager"
            [formControlName]="mgc.cAdresAanvrager"
            mgformat
            required
          />
          <mgError [magic]=mgc.cAdresAanvrager></mgError>
        </mat-form-field>

        <!--Postcode-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lPostcode) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cPostcode"
            [formControlName]="mgc.cPostcode"
            mgformat
            required
          />
          <mgError [magic]=mgc.cPostcode></mgError>
        </mat-form-field>

        <!--Plaats-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lPlaats) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cPlaats"
            [formControlName]="mgc.cPlaats"
            mgformat
            required
          />
          <mgError [magic]=mgc.cPlaats></mgError>
        </mat-form-field>
      </div>

      <!--Gegevens Partner-->
      <div class="col-lg-4">
        <h5>Gegevens Partner</h5>

        <!--Voornaam Partner-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lVoornaamPartner) }}
          </mat-label>
          <input
            matInput
            [magic]="mgc.cVoornaamPartner"
            [formControlName]="mgc.cVoornaamPartner"
            [required]="mg.getMustInput('cVoornaamPartner')"
            mgformat
          />
          <mgError [magic]=mgc.cVoornaamPartner></mgError>
        </mat-form-field>

        <!--Achternaam Partner-->
        <mat-form-field>
          <mat-label>
            {{ mg.getText(mgc.lAchternaamPartner) }}
          </mat-label>
          <input
            matInput
            [magic]="mgc.cAchternaamPartner"
            [required]="mg.getMustInput('cAchternaamPartner')"
            [formControlName]="mgc.cAchternaamPartner"
            mgformat
          />
          <mgError [magic]=mgc.cAchternaamPartner></mgError>
        </mat-form-field>

        <!--Geboortedatum-->
        <mat-form-field [magic]="mgc.cGeboorteDatum_partner" [eventsOnly]=true>
          <mat-label>{{ mg.getText(mgc.lGeboorteDatum_partner) }}</mat-label>
          <input
            matInput
            [matDatepicker]="cGeboorteDatum_partner"
            [magic]="mgc.cGeboorteDatum_partner"
            [formControlName]="mgc.cGeboorteDatum_partner"
            [required]="mg.getMustInput('cGeboorteDatum_partner')"
            [style.visibility]="mg.getVisible(mgc.cGeboorteDatum_partner)"
            mgFormat
          />
          <mat-datepicker-toggle
            matSuffix [for]="cGeboorteDatum_partner"
            *ngIf="mg.checkIsReadOnly(mgc.cGeboorteDatum_partner)">
          </mat-datepicker-toggle>
          <mat-datepicker #cGeboorteDatum_partner></mat-datepicker>
        </mat-form-field>


        <!--Geslacht Partner-->
        <mat-form-field>
          <mat-label>
            Geslacht partner
          </mat-label>
          <mat-select
            [magic]="mgc.cGeslachtPartner"
            [formControlName]="mgc.cGeslachtPartner"
            [required]="mg.getMustInput('cGeslachtPartner')"
          >
            <mat-option
              *ngFor="let o of mg.getItemListValues(mgc.cGeslachtPartner);"
              [value]="o.index">
              {{o.displayValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <h5>Aanvullende gegevens afnemer</h5>

        <!--Email-->
        <mat-form-field>
          <mat-label [class.emptyTest]="mg.getValue('cEmail') == '' && checkEmptyField">
            {{ mg.getText(mgc.lEmail) }}
          </mat-label>
          <input
            matInput
            [magic]="mgc.cEmail"
            [formControlName]="mgc.cEmail"
            mgformat
            matTooltip="Dit emailadres zal alleen gebruikt worden voor zeer dringende zaken zoals terughalen product. Verder blijft alle correspondentie via de hulpverlener gaan. Heeft cliënt geen e-mail dan het e-mailadres van de hulpverlener invullen."
            matTooltipPosition="right"
            Tooltip
            required
          />
          <mgError [magic]=mgc.cEmail></mgError>
        </mat-form-field>

        <!--Telefoon-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lTelefoon) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cTelefoon"
            [formControlName]="mgc.cTelefoon"
            mgformat
            required
          />
          <mgError [magic]=mgc.cTelefoon></mgError>
        </mat-form-field>
      </div>


      <div class="col-lg-4">

        <h5>Gegevens aanvragende instantie</h5>

        <!--Naam organisatie-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lNaamOrg) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cNaam_Org"
            [formControlName]="mgc.cNaam_Org"
            mgformat
            required
            matTooltip="De aanvragende instantie dient een professionele hulpverleningsorganisatie te zijn."
            matTooltipPosition="left"
          />
          <mgError [magic]=mgc.cNaam_Org></mgError>
        </mat-form-field>

        <!--lContactpersoon_org-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lContactpersoon_org) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cContactpersoon_org"
            [formControlName]="mgc.cContactpersoon_org"
            mgformat
            required
          />
          <mgError [magic]=mgc.cContactpersoon_org></mgError>
        </mat-form-field>

        <!--Email Organisatie-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lEmailOrg) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cEmail_Org"
            [formControlName]="mgc.cEmail_Org"
            mgformat
            required
          />
          <mgError [magic]=mgc.cEmail_Org></mgError>
        </mat-form-field>

        <!--Telefoon Organisatie-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lTelefoonOrg) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cTelefoonnummer_Org"
            [formControlName]="mgc.cTelefoonnummer_Org"
            mgformat
            required
          />
          <mgError [magic]=mgc.cTelefoonnummer_Org></mgError>
        </mat-form-field>

        <h5>Bestaande klant?</h5>

        <!--ComboBox bestaande klant-->
        <mat-form-field>
          <mat-label>Bestaande Klant</mat-label>
          <mat-select
            [magic]="mgc.cComboBoxKlant"
            [formControlName]="mgc.cComboBoxKlant"
            required
          >
            <mat-option
              *ngFor="let o of mg.getItemListValues(mgc.cComboBoxKlant);"
              [value]="o.index"
            >
              {{o.displayValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--Referentienummer-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lReferentienummer) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cReferentienummer"
            [formControlName]="mgc.cReferentienummer"
            mgformat
          />
          <mgError [magic]=mgc.cReferentienummer></mgError>
        </mat-form-field>

        <h5>Voorkeur uitgiftepunt</h5>

        <!--ComboBox Uitgiftepunten-->
        <mat-form-field>
          <mat-label>Uitgiftepunten</mat-label>
          <mat-select
            [magic]="mgc.cComboBoxUitgifte"
            [formControlName]="mgc.cComboBoxUitgifte"
          >
            <mat-option
              *ngFor="let o of mg.getItemListValues(mgc.cComboBoxUitgifte);"
              [value]="o.index"
            >
              {{o.displayValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="categoryBlock">
      <mat-card>
        <h5>Voeg hieronder uw kinderen toe</h5>
        <br>
        <magic-subform [magic]="mgc.cChildren">
        </magic-subform>
      </mat-card>
    </div>


    <div class="row categoryBlockWeekGeld">
      <div class="col-lg-6">
        <h5>Is er sprake van financiële bewindvoering en is er een budgetplan?
          Dan het WEEKgeld graag hier invullen en het budgetplan toevoegen
          Is er wel weekgeld maar geen budgetplan dan de actuele cijfers hieronder
          invullen en het weekgeld vermelden bij aflossing:</h5>
      </div>
    </div>

    <div class="row categoryBlockWeekGeld">
      <div class="col-6">
        <!--Weekgeld-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lWeekgeld) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cWeekgeld"
            [formControlName]="mgc.cWeekgeld"
            mgformat
            type='number'
            matTooltip="Bij weekgeld BUDGETPLAN meesturen!"
            matTooltipPosition="right"
          />
          <mgError [magic]=mgc.cWeekgeld></mgError>
          <button mat-button matSuffix mat-icon-button [magic]="mgc.ShowImages"
                  [style.visibility]="mg.getVisible(mgc.ShowImages)">
            <mat-icon>note_add</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-1">
    <ng-container *ngIf="mg.getValue(mgc.v_FIleFound) || mg.getValue(mgc.v_FileToegevoegd)">
      <label>Bestand toegevoegd</label>
    </ng-container>
      </div>
    </div>

    <div class="row categoryBlock">
      <div class="col-lg-4">
        <h5
          matTooltip="Alle inkomsten van alle volwassen 18+ worden meegerekend. Niet meegeteld worden: - Vakantietoeslag - Kinderbijslag, studiefinanciering, (dit zijn doeluitkering tbv kinderen, dus ook  geen specifieke uitgaven inzake kinderen meetellen die hieruit voldaan kunnen worden) - Persoonsgebonden budget (wordt geacht te worden besteed aan bijzondere kosten, die dan ook niet als uitgaven mogen worden opgevoerd)- Neveninkomsten van kinderen zoals krantenwijk, bijbaantje e.d."
          matTooltipPosition="right"
        >Inkomsten

        </h5>


        <!--Nettoloon-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lNettoLoon) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cNettoloon"
            [formControlName]="mgc.cNettoloon"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cNettoloon></mgError>
        </mat-form-field>

        <!--Uitkering-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lUiterking) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cUitkering"
            [formControlName]="mgc.cUitkering"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cUitkering></mgError>
        </mat-form-field>

        <!--Nettoloon Partner-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lNettoLoonPartner) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cNettoPartner"
            [formControlName]="mgc.cNettoPartner"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cNettoPartner></mgError>
        </mat-form-field>

        <!--Uitkering Partner-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lUitkeringPartner) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cUitkeringPartner"
            [formControlName]="mgc.cUitkeringPartner"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cUitkeringPartner></mgError>
        </mat-form-field>

        <!--BelastingTeruggave-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lBelastingTeruggave) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cBelastingTeruggave"
            [formControlName]="mgc.cBelastingTeruggave"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cBelastingTeruggave></mgError>
        </mat-form-field>

        <!--BijstandDoel-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lBijstandDoel) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cBijstandDoel"
            [formControlName]="mgc.cBijstandDoel"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cBijstandDoel></mgError>
        </mat-form-field>
      </div>

      <div class="col-lg-4 middleCol">

        <!--Alimentatie-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lAlimentatie) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cAlimentatie"
            [formControlName]="mgc.cAlimentatie"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cAlimentatie></mgError>
        </mat-form-field>

        <!--HuurToeslag-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lHuurToeslag) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cHuurToeslag"
            [formControlName]="mgc.cHuurToeslag"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cHuurToeslag></mgError>
        </mat-form-field>

        <!--ZorgToeslag-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lZorgToeslag) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cZorgToeslag"
            [formControlName]="mgc.cZorgToeslag"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cZorgToeslag></mgError>
        </mat-form-field>

        <!--KostgeldKind-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lKostgeldKind) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cKostgeldKind"
            [formControlName]="mgc.cKostgeldKind"
            mgformat
            type='number'
            matTooltip="Inwonende kinderen vanaf 18 jaar die een inkomen hebben uit werk of uitkering (dus geen bijbaantje) worden geacht minstens € 200 per maand aan kostgeld te betalen."
            matTooltipPosition="right"
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cKostgeldKind></mgError>
        </mat-form-field>

        <!--Kindergebondenbudget-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lKindgebondenBudget) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cKGB"
            [formControlName]="mgc.cKGB"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cKGB></mgError>
        </mat-form-field>
      </div>

      <div class="col-lg-4">
        <h5>Uitgaven</h5>

        <!--Huur Hypotheek-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lHuurHypotheek) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cHuurHypotheek"
            [formControlName]="mgc.cHuurHypotheek"
            mgformat
            type='number'
            matTooltip="Huur of Hypotheek (aflossing/rente), maar dan ook belastingteruggave meerekenen bij inkomsten."
            matTooltipPosition="left"
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cHuurHypotheek></mgError>
        </mat-form-field>

        <!--EnergieWater-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lEnergieWater) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cEnergieWater"
            [formControlName]="mgc.cEnergieWater"
            mgformat
            type='number'
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cEnergieWater></mgError>
        </mat-form-field>

        <!--Premie ziektekostenverzekering-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lbl_PZV) }}</mat-label>
          <input
              matInput
              [magic]="mgc.cPremieZKV"
              [formControlName]="mgc.cPremieZKV"
              mgformat
              type='number'
              (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cPremieZKV></mgError>
        </mat-form-field>

        <!--GemWaBelasting-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lGemWaBelasting) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cBelastingWater"
            [formControlName]="mgc.cBelastingWater"
            mgformat
            type='number'
            matTooltip="Er is vaak sprake van kwijtscheldingsmogelijkheid of vermindering! Hiervoor kunt u een beroep doen op Bijzondere Bijstand.."
            matTooltipPosition="left"
            (focusout)="bereken()"
          />
          <mgError [magic]=mgc.cBelastingWater></mgError>
        </mat-form-field>


        <!--PersHuisUitgaven-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lPersHuisUitgaven) }}</mat-label>
          <input
            matInput
            [magic]="mgc.cPersHuisUitgaven"
            [formControlName]="mgc.cPersHuisUitgaven"
            mgformat
            readonly
            matTooltip="Hieronder vallen: reserving eigen risico, eigen bijdrage medicatie, persoonlijke verzorging, tv/internet/mobiele telefoon, was/schoonmaakmiddelen, vervoer, overige verzekeringen."
            matTooltipPosition="left"
          />
        </mat-form-field>

      </div>
    </div>

    <div class="categoryBlock" [style.display]="mg.getVisible(mgc.cOverigeUitgaven) === 'visible' ? '' : 'none'">
      <mat-card>
        <h5>Voeg hier uw extra aflossingen toe</h5>
        <br>
        <magic-subform [magic]="mgc.cOverigeUitgaven">
        </magic-subform>
      </mat-card>
    </div>


    <div class="row categoryBlock">
      <div class="col-lg-4">
        <h5
          matTooltip="Deze bedragen worden berekend aan de hand van het ingevulde formulier."
          matTooltipPosition="right"
        >
          Totaal overzicht
        </h5>

        <!--TotaalInkomsten-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lTotaalInkomsten) }}</mat-label>
          <input
            matInput
            [magic]="mgc.totaal_inkomsten"
            [formControlName]="mgc.totaal_inkomsten"
            mgformat
            readonly
          />
        </mat-form-field>
      </div>

      <div class="col-lg-4">
        <h5
          matTooltip="Deze bedragen worden berekend aan de hand van het ingevulde formulier."
          matTooltipPosition="right"
        >
          Totaal uitgaven
        </h5>

        <!--lTotaalUitgaven-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lTotaalUitgaven) }}</mat-label>
          <input
            matInput
            [magic]="mgc.totaal_uitgaven"
            [formControlName]="mgc.totaal_uitgaven"
            mgformat
            readonly
          />
          <mgError [magic]=mgc.totaal_uitgaven></mgError>
        </mat-form-field>
      </div>

      <div class="col-lg-4">
        <h5
          matTooltip="Deze bedragen worden berekend aan de hand van het ingevulde formulier."
          matTooltipPosition="right">
          Totaal overzicht
        </h5>

        <!--lNettoTeBesteden-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lNettoTeBesteden) }}</mat-label>
          <input
            matInput
            [magic]="mgc.netto_tebesteden"
            [formControlName]="mgc.netto_tebesteden"
            mgformat
            readonly
          />
          <mgError [magic]=mgc.netto_tebesteden></mgError>
        </mat-form-field>
      </div>

    </div>

    <div class="row categoryBlock">
      <div class="col-lg-12">
        <!--Toelichting-->
        <mat-form-field>
          <mat-label>{{ mg.getText(mgc.lToelichting1) }}</mat-label>
          <textarea
            matInput
            [magic]="mgc.cToelichting1"
            [formControlName]="mgc.cToelichting1"
            mgFormat
            cdkTextareaAutosize
            cdkAutosizeMinRows="15">
                        </textarea>
          <mgError [magic]=mgc.cToelichting1></mgError>
        </mat-form-field>
      </div>
    </div>

    <div class="row categoryBlock">
      <div class="col-lg-12">

        <div>
          <mat-checkbox
            [magic]="mgc.cbClient"
            [formControlName]="mgc.cbClient"
          >
            {{ mg.getText(mgc.cbClient) }}
          </mat-checkbox>
        </div>

        <div>
          <mat-checkbox
            [magic]="mgc.cbAanvrager"
            [formControlName]="mgc.cbAanvrager"
          >
            {{ mg.getText(mgc.cbAanvrager) }}
          </mat-checkbox>
        </div>

        <div>
          <mat-checkbox
            [magic]="mgc.cbInwoner"
            [formControlName]="mgc.cbInwoner"
          >
            {{ mg.getText(mgc.cbInwoner) }}
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="row rowForm">
      <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cVerzenden">
        {{ mg.getValue(mgc.cVerzenden)}}
      </button>
    </div>
  </div>
</div>
