import { Component } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from './Aanvraagformulier.mg.controls.g';


import { TaskBaseMagicComponent, magicProviders } from '@magic-xpa/angular';

@Component({
    selector: 'mga-Aanvraagformulier',
    providers: [...magicProviders],
    templateUrl: './Aanvraagformulier.component.html'
})
export class Aanvraagformulier extends TaskBaseMagicComponent {

    mgc = MgControlName;
    mgcp = MgCustomProperties;
    mgfc: MgFormControlsAccessor;
    public checkEmptyField = false;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }

    bereken(){
        console.log('test');
        this.mg.simulateClick(this.mgc.cBerekenen);
    }

  scroll() {
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth' });
    }
}
