import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    OverigUitgaven = "OverigUitgaven",
        Table1 = "Table1",
        ColAflossing = "ColAflossing",
        cAflossing = "cAflossing",
        ColUitlegAflossing = "ColUitlegAflossing",
        cNaamAflossing = "cNaamAflossing",
        ColBTN = "ColBTN",
        ColBTN2 = "ColBTN2",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        cToevoegen = "cToevoegen",
        pb_Toevoegen = "pb_Toevoegen",
        pb_Delete = "pb_Delete",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'ColAflossing',
        'ColUitlegAflossing',
        'ColBTN',
        'ColBTN2',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cAflossing(): FormControl {
        return this.getTableChildFormControl(MgControlName.cAflossing);
    }

    get cNaamAflossing(): FormControl {
        return this.getTableChildFormControl(MgControlName.cNaamAflossing);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}