<div
    novalidate
    [formGroup]="screenFormGroup"
    [magic]="mgc.Uitgiftepunten"
>
    <div class="mb-2">
        <button
            matSuffix
            color="primary"
            mat-raised-button
            [magic]="mgc.pb_Toevoegen">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div class="table-container">
    <table 
        mat-table 
        [dataSource]="dataSource" 
        matSort matSortDisableClear
        (matSortChange)="tableService.sortData($event)">
        <!-- Table Columns go here -->

        <!--cGegevensUitgifte-->
        <ng-container 
            [magic]="mgc.Column30" 
            [matColumnDef]="mgc.Column30">
            <th 
                mat-header-cell 
                *matHeaderCellDef 
                mat-sort-header
                >
                {{mg.getText(mgc.Column30)}}
            </th>

            <td 
                mat-cell 
                *matCellDef="let row" 
                magicMark="magicTableRowContainer"
                >
                <ng-container *ngIf="mg.ifRowCreated(row)">

                    <!-- Edit Section -->
                    <ng-container 
                        [formGroup]="mg.getFormGroupByRow(row.rowId)"
                        *ngIf="mg.isRowInRowEditing(row)"
                        >
                        <mat-form-field>
                            <input 
                                matInput 
                                [magic]="mgc.cGegevensUitgifte"
                                [placeholder]="mg.getPlaceholder(mgc.cGegevensUitgifte)" 
                                [formControlName]="mgc.cGegevensUitgifte"
                                mgFormat>
                        </mat-form-field>
                        <mgError class="errors" [magic]="mgc.cGegevensUitgifte"></mgError>
                    </ng-container>

                    <!-- Label -->
                    <ng-container 
                        [formGroup]="mg.getFormGroupByRow(row.rowId)"
                        *ngIf="!mg.isRowInRowEditing(row)"
                        >
                        <ng-container 
                            [magic]="mgc.cGegevensUitgifte" 
                            [rowId]="row.rowId"
                            >
                            {{mg.getValue(mgc.cGegevensUitgifte, row.rowId)}}
                        </ng-container>
                    </ng-container>

                </ng-container>
            </td>
        </ng-container>

        <!--Buttons-->
        <ng-container 
            [magic]="mgc.Column8" 
            [matColumnDef]="mgc.Column8">

            <th 
                mat-header-cell 
                *matHeaderCellDef 
                mat-sort-header></th>
            <td 
                mat-cell 
                *matCellDef="let row" 
                magicMark="magicTableRowContainer">

                <div *ngIf="mg.ifRowCreated(row)">
                    <button 
                        mat-button 
                        matSuffix 
                        mat-icon-button
                        *ngIf="!mg.isRowInRowEditing(row)"
                        [magic]="mgc.cWijzigen"
                        [rowId]="row.rowId">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button 
                        mat-button
                        matSuffix
                        mat-icon-button
                        *ngIf="mg.isRowInRowEditing(row)" 
                        [magic]="mgc.cOpslaan" 
                        [rowId]="row.rowId">
                        <mat-icon>save</mat-icon>
                    </button>
                    <button 
                        mat-button
                        matSuffix
                        mat-icon-button 
                        *ngIf="mg.isRowInRowEditing(row)" 
                        [magic]="mgc.cAnnuleer"
                        [rowId]="row.rowId">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <button
                        mat-button
                        matSuffix
                        mat-icon-button
                        [magic]="mgc.cVerwijderen"
                        [rowId]="row.rowId"
                        [disabled]="mg.isDisabled(mgc.cVerwijderen , row.rowId)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr 
            mat-header-row 
            *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr 
            [ngClass]="{ 'selected': selection.isSelected(row)}" 
            [class.editmode]="mg.isRowInRowEditing(row)"
            mat-row *matRowDef="let row; columns: displayedColumns" 
            [magicRow]="row?.rowId"></tr>
    </table>
    </div>
    <mat-paginator 
            #paginator 
            [pageSize]="30" 
            [pageSizeOptions]="[15, 30, 50]"
            (page)="tableService.mgOnPaginateChange($event)">
    </mat-paginator>
</div>
