<div novalidate [formGroup]="screenFormGroup">
    <div [magic]="mgc.Gebruikers">

        <div class="mb-2">
            <button
                matSuffix
                color="primary"
                mat-raised-button
                [magic]="mgc.pb_Toevoegen">
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div class="table-container">
            <table 
                mat-table 
                [dataSource]="dataSource" 
                matSort matSortDisableClear
                (matSortChange)="tableService.sortData($event)">
                <!-- Table Columns go here -->

                <!--achternaam-->
                <ng-container 
                    [magic]="mgc.ColAchternaam" 
                    [matColumnDef]="mgc.ColAchternaam">
                    <th 
                        mat-header-cell 
                        *matHeaderCellDef 
                        mat-sort-header
                        >
                        {{mg.getText(mgc.ColAchternaam)}}
                    </th>

                    <td 
                        mat-cell 
                        *matCellDef="let row" 
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container 
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input 
                                        matInput 
                                        [magic]="mgc.achternaam"
                                        [placeholder]="mg.getPlaceholder(mgc.achternaam)" 
                                        [formControlName]="mgc.achternaam"
                                        mgFormat>
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.achternaam"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container 
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container 
                                    [magic]="mgc.achternaam" 
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.achternaam, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--voornaam-->
                <ng-container 
                [magic]="mgc.ColVoornaam" 
                [matColumnDef]="mgc.ColVoornaam">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header
                    >
                    {{mg.getText(mgc.ColVoornaam)}}
                </th>

                <td 
                    mat-cell 
                    *matCellDef="let row" 
                    magicMark="magicTableRowContainer"
                    >
                    <ng-container *ngIf="mg.ifRowCreated(row)">

                        <!-- Edit Section -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="mg.isRowInRowEditing(row)"
                            >
                            <mat-form-field>
                                <input 
                                    matInput 
                                    [magic]="mgc.voornaam"
                                    [placeholder]="mg.getPlaceholder(mgc.voornaam)" 
                                    [formControlName]="mgc.voornaam"
                                    mgFormat>
                            </mat-form-field>
                            <mgError class="errors" [magic]="mgc.voornaam"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.voornaam" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getValue(mgc.voornaam, row.rowId)}}
                            </ng-container>
                        </ng-container>

                    </ng-container>
                </td>
                </ng-container>

                <!--email-->
                <ng-container 
                [magic]="mgc.ColEmail" 
                [matColumnDef]="mgc.ColEmail">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header
                    >
                    {{mg.getText(mgc.ColEmail)}}
                </th>

                <td 
                    mat-cell 
                    *matCellDef="let row" 
                    magicMark="magicTableRowContainer"
                    >
                    <ng-container *ngIf="mg.ifRowCreated(row)">

                        <!-- Edit Section -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="mg.isRowInRowEditing(row)"
                            >
                            <mat-form-field>
                                <input 
                                    matInput 
                                    [magic]="mgc.email"
                                    [placeholder]="mg.getPlaceholder(mgc.email)" 
                                    [formControlName]="mgc.email"
                                    mgFormat>
                            </mat-form-field>
                            <mgError class="errors" [magic]="mgc.email"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.email" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getValue(mgc.email, row.rowId)}}
                            </ng-container>
                        </ng-container>

                    </ng-container>
                </td>
                </ng-container>

                <!--Telefoon-->
                <ng-container 
                [magic]="mgc.ColTelefoon" 
                [matColumnDef]="mgc.ColTelefoon">
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header
                    >
                    {{mg.getText(mgc.ColTelefoon)}}
                </th>

                <td 
                    mat-cell 
                    *matCellDef="let row" 
                    magicMark="magicTableRowContainer"
                    >
                    <ng-container *ngIf="mg.ifRowCreated(row)">

                        <!-- Edit Section -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="mg.isRowInRowEditing(row)"
                            >
                            <mat-form-field>
                                <input 
                                    matInput 
                                    [magic]="mgc.cTelefoon"
                                    [placeholder]="mg.getPlaceholder(mgc.cTelefoon)" 
                                    [formControlName]="mgc.cTelefoon"
                                    mgFormat>
                            </mat-form-field>
                            <mgError class="errors" [magic]="mgc.cTelefoon"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.cTelefoon" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getValue(mgc.cTelefoon, row.rowId)}}
                            </ng-container>
                        </ng-container>

                    </ng-container>
                </td>
                </ng-container>

                <!--Role-->
                <ng-container
                [magic]="mgc.ColRole"
                [matColumnDef]="mgc.ColRole"
            >
                <th 
                mat-header-cell 
                *matHeaderCellDef 
                mat-sort-header>
                {{mg.getText(mgc.ColRole)}}
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    magicMark="magicTableRowContainer"
                >
                    <ng-container *ngIf="mg.ifRowCreated(row)">
                            <!-- Edit Section -->
                        <ng-container [formGroup]="mg.getFormGroupByRow(row.rowId)"
                         *ngIf="mg.isRowInRowEditing(row)">
                                <mat-form-field [matTooltip]="mg.getTitle(mgc.cRole, row.rowId)">
                                    <mat-select
                                        [magic]="mgc.cRole"
                                        [rowId]="row.rowId"
                                        [formControlName]="mgc.cRole"
                                    >
                                        <mat-option
                                            *ngFor="let o of mg.getItemListValues(mgc.cRole, row.rowId);"
                                            [value]="o.index"
                                        >
                                            {{o.displayValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.cRole"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.cRole" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getDisplayValue(mgc.cRole, row.rowId)}}
                            </ng-container>
                        </ng-container> 
                </ng-container>            
                </td>
                </ng-container>

                <!-- Buttons -->
                <ng-container
                    stickyEnd
                    [magic]="mgc.ColBTN" 
                    [matColumnDef]="mgc.ColBTN">

                    <th 
                        mat-header-cell 
                        *matHeaderCellDef ></th>
                    <td 
                        mat-cell 
                        *matCellDef="let row" 
                        magicMark="magicTableRowContainer"
                        class="sticky-table-icon">

                        <ng-container *ngIf="mg.ifRowCreated(row)">
                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button
                                tabindex="-1"
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.cVerwijderen" 
                                [rowId]="row.rowId">
                                <mat-icon>delete</mat-icon>
                                </button>                                
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button 
                                mat-button 
                                matSuffix 
                                mat-icon-button
                                [magic]="mgc.SaveRowEditing"
                                [rowId]="row.rowId">
                                <mat-icon>save</mat-icon>
                                </button>
                            </ng-container>                           
                            
                        </ng-container>
                    </td>
                </ng-container>


                <!--Buttons2-->
                <ng-container 
                    stickyEnd
                    [magic]="mgc.ColBTN2" 
                    [matColumnDef]="mgc.ColBTN2">
                    <th 
                        mat-header-cell 
                        *matHeaderCellDef 
                        mat-sort-header></th>
                    <td 
                        mat-cell 
                        *matCellDef="let row" 
                        magicMark="magicTableRowContainer"
                        class="sticky-table-icon">

                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button 
                                mat-button 
                                matSuffix 
                                mat-icon-button
                                [magic]="mgc.EnterRowEditing"
                                [rowId]="row.rowId">
                                <mat-icon>edit</mat-icon>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button 
                                mat-button 
                                matSuffix 
                                mat-icon-button
                                [magic]="mgc.CancelRowEditing"
                                [rowId]="row.rowId">
                                <mat-icon>cancel</mat-icon>
                                </button>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <tr 
                    mat-header-row 
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr 
                    [ngClass]="{ 'selected': selection.isSelected(row)}" 
                    [class.editmode]="mg.isRowInRowEditing(row)"
                    mat-row *matRowDef="let row; columns: displayedColumns" 
                    [magicRow]="row?.rowId"></tr>
            </table>
        </div>

        <mat-paginator 
            #paginator 
            [pageSize]="30" 
            [pageSizeOptions]="[15, 30, 50]"
            (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>      

    </div>
</div>