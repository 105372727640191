import { UserManagement as User_Management_UserManagement } from './User Management/UserManagement.component';
import { Gebruikers as User_Management_Gebruikers_Gebruikers } from './User Management/Gebruikers/Gebruikers.component';
import { NieuweGebruiker as User_Management_NieuweGebruiker_NieuweGebruiker } from './User Management/NieuweGebruiker/NieuweGebruiker.component';
import { Main as StartProgram_Main } from './StartProgram/Main.component';
import { Main as Menu_Main } from './Menu/Main.component';
import { UserManagement as Gebruikers_Beheer_UserManagement } from './Gebruikers Beheer/UserManagement.component';
import { Login as Login_Login } from './Login/Login.component';
import { UitgiftepuntBeheer as Uitgiftepunt_Beheer_UitgiftepuntBeheer } from './Uitgiftepunt Beheer/UitgiftepuntBeheer.component';
import { Uitgiftepunten as Uitgiftepunt_Beheer_Uitgiftepunten_Uitgiftepunten } from './Uitgiftepunt Beheer/Uitgiftepunten/Uitgiftepunten.component';
import { Openingstijden as Uitgiftepunt_Beheer_Uitgiftepunten_Openingstijden_Openingstijden } from './Uitgiftepunt Beheer/Uitgiftepunten/Openingstijden/Openingstijden.component';
import { AanvraagBeheer as Aanvraag_Beheer_AanvraagBeheer } from './Aanvraag Beheer/AanvraagBeheer.component';
import { Aanvragen as Aanvraag_Beheer_Aanvragen_Aanvragen } from './Aanvraag Beheer/Aanvragen/Aanvragen.component';
import { Controlelogin as Login_Controle_login_Controlelogin } from './Login/Controle login/Controlelogin.component';
import { EditInstellingen as Account_Instellingen_EditInstellingen } from './Account Instellingen/EditInstellingen.component';
import { EmailTemplates as Email_Templates_EmailTemplates } from './Email Templates/EmailTemplates.component';
import { Templates as Email_Templates_Templates_Templates } from './Email Templates/Templates/Templates.component';
import { Aanvraagformulier as Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Aanvraagformulier } from './Aanvraag Beheer/Aanvragen/Aanvraagformulier/Aanvraagformulier.component';
import { OverigUitgaven as Aanvraag_Beheer_Aanvragen_Aanvraagformulier_OverigUitgaven_OverigUitgaven } from './Aanvraag Beheer/Aanvragen/Aanvraagformulier/OverigUitgaven/OverigUitgaven.component';
import { Children as Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Children_Children } from './Aanvraag Beheer/Aanvragen/Aanvraagformulier/Children/Children.component';
import { Gebruikers as Gebruikers_Beheer_Gebruikers_Gebruikers } from './Gebruikers Beheer/Gebruikers/Gebruikers.component';
import { NieuweGebruiker as Gebruikers_Beheer_Gebruikers_EditGebruiker_NieuweGebruiker } from './Gebruikers Beheer/Gebruikers/EditGebruiker/NieuweGebruiker.component';
import { Aanvraagformulier as Aanvraagformulier_Aanvraagformulier } from './Aanvraagformulier/Aanvraagformulier.component';
import { OverigUitgaven as Aanvraagformulier_OverigUitgaven_OverigUitgaven } from './Aanvraagformulier/OverigUitgaven/OverigUitgaven.component';
import { Children as Aanvraagformulier_Children_Children } from './Aanvraagformulier/Children/Children.component';
import { UploadFiles as UploadFiles_UploadFiles } from './UploadFiles/UploadFiles.component';
import { ChangePWD as Account_Instellingen_ChangePWD_ChangePWD } from './Account Instellingen/ChangePWD/ChangePWD.component';
import { FileTransfer as FileTransfer_FileTransfer } from './FileTransfer/FileTransfer.component';
import { DownloadDocumentenClient as DownloadDocumentenClient_DownloadDocumentenClient } from './DownloadDocumentenClient/DownloadDocumentenClient.component';

export const title = "";

export const magicGenCmpsHash = {               DownloadDocumentenClient_DownloadDocumentenClient:DownloadDocumentenClient_DownloadDocumentenClient,
                      FileTransfer_FileTransfer:FileTransfer_FileTransfer,
                      Account_Instellingen_ChangePWD_ChangePWD:Account_Instellingen_ChangePWD_ChangePWD,
                      UploadFiles_UploadFiles:UploadFiles_UploadFiles,
                      Aanvraagformulier_Aanvraagformulier:Aanvraagformulier_Aanvraagformulier,
              Aanvraagformulier_OverigUitgaven_OverigUitgaven:Aanvraagformulier_OverigUitgaven_OverigUitgaven,
              Aanvraagformulier_Children_Children:Aanvraagformulier_Children_Children,
                      Gebruikers_Beheer_Gebruikers_Gebruikers:Gebruikers_Beheer_Gebruikers_Gebruikers,
              Gebruikers_Beheer_Gebruikers_EditGebruiker_NieuweGebruiker:Gebruikers_Beheer_Gebruikers_EditGebruiker_NieuweGebruiker,
         Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Aanvraagformulier:Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Aanvraagformulier,
              Aanvraag_Beheer_Aanvragen_Aanvraagformulier_OverigUitgaven_OverigUitgaven:Aanvraag_Beheer_Aanvragen_Aanvraagformulier_OverigUitgaven_OverigUitgaven,
              Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Children_Children:Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Children_Children,
                                          
                      Email_Templates_Templates_Templates:Email_Templates_Templates_Templates,
                      Email_Templates_EmailTemplates:Email_Templates_EmailTemplates,
                      Account_Instellingen_EditInstellingen:Account_Instellingen_EditInstellingen,
                      Login_Controle_login_Controlelogin:Login_Controle_login_Controlelogin,

                      Aanvraag_Beheer_AanvraagBeheer:Aanvraag_Beheer_AanvraagBeheer,
              Aanvraag_Beheer_Aanvragen_Aanvragen:Aanvraag_Beheer_Aanvragen_Aanvragen,
                      StartProgram_Main:StartProgram_Main,
              Menu_Main:Menu_Main,
              Gebruikers_Beheer_UserManagement:Gebruikers_Beheer_UserManagement,
              Login_Login:Login_Login,
              Uitgiftepunt_Beheer_UitgiftepuntBeheer:Uitgiftepunt_Beheer_UitgiftepuntBeheer,
              Uitgiftepunt_Beheer_Uitgiftepunten_Uitgiftepunten:Uitgiftepunt_Beheer_Uitgiftepunten_Uitgiftepunten,
              Uitgiftepunt_Beheer_Uitgiftepunten_Openingstijden_Openingstijden:Uitgiftepunt_Beheer_Uitgiftepunten_Openingstijden_Openingstijden,
                      User_Management_UserManagement:User_Management_UserManagement,
              User_Management_Gebruikers_Gebruikers:User_Management_Gebruikers_Gebruikers,
              User_Management_NieuweGebruiker_NieuweGebruiker:User_Management_NieuweGebruiker_NieuweGebruiker,
       
};

export const magicGenComponents = [ DownloadDocumentenClient_DownloadDocumentenClient ,  FileTransfer_FileTransfer ,  Account_Instellingen_ChangePWD_ChangePWD ,  UploadFiles_UploadFiles ,  Aanvraagformulier_Aanvraagformulier,
Aanvraagformulier_OverigUitgaven_OverigUitgaven,
Aanvraagformulier_Children_Children ,  Gebruikers_Beheer_Gebruikers_Gebruikers,
Gebruikers_Beheer_Gebruikers_EditGebruiker_NieuweGebruiker ,   Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Aanvraagformulier,
Aanvraag_Beheer_Aanvragen_Aanvraagformulier_OverigUitgaven_OverigUitgaven,
Aanvraag_Beheer_Aanvragen_Aanvraagformulier_Children_Children ,
Email_Templates_Templates_Templates ,  Email_Templates_EmailTemplates ,  Account_Instellingen_EditInstellingen ,  Login_Controle_login_Controlelogin,
Aanvraag_Beheer_AanvraagBeheer,
Aanvraag_Beheer_Aanvragen_Aanvragen ,  StartProgram_Main,
Menu_Main,
Gebruikers_Beheer_UserManagement,

Login_Login,
Uitgiftepunt_Beheer_UitgiftepuntBeheer,
Uitgiftepunt_Beheer_Uitgiftepunten_Uitgiftepunten,
Uitgiftepunt_Beheer_Uitgiftepunten_Openingstijden_Openingstijden ,  User_Management_UserManagement,
User_Management_Gebruikers_Gebruikers,
User_Management_NieuweGebruiker_NieuweGebruiker 
];


export const LazyLoadModulesMap = {};