import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    DownloadDocumentenClient = "DownloadDocumentenClient",
        vt_RelatieOmschrijving = "vt_RelatieOmschrijving",
        btn_UploadDocument = "btn_UploadDocument",
        pb_Save = "pb_Save",
        Table2 = "Table2",
        cCFileName = "cCFileName",
        FileName = "FileName",
        Column67108886 = "Column67108886",
        btn_ShowDocument = "btn_ShowDocument",
        pb_Delete = "pb_Delete",
        FNL = "FNL",
        vFileName = "vFileName",
        Blob = "Blob",
        pb_HiddenSave = "pb_HiddenSave",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'cCFileName',
        'Column67108886',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get vt_RelatieOmschrijving(): FormControl {
        return this.fg.controls[MgControlName.vt_RelatieOmschrijving] as FormControl;
    }

    get FileName(): FormControl {
        return this.getTableChildFormControl(MgControlName.FileName);
    }

    get vFileName(): FormControl {
        return this.fg.controls[MgControlName.vFileName] as FormControl;
    }

    get Blob(): FormControl {
        return this.fg.controls[MgControlName.Blob] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}