<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        [magic]="mgc.Main"
    >
        <nav class="navbar navbar-light bg-light">
            <div class="container-fluid" id="navbar">          
                <ul class="navbar-nav">
                    <a class="navbar-brand" href="#">
                        <img src="assets/images/PNG/Logo/cropped-logo.png" alt="" width="60" height="60">
                    </a>  
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            [magic]="mgc.cAccountInstellingen"
                        >
                            Account instellingen
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            [magic]="mgc.cAanvragen"
                            [style.visibility]="mg.getVisible(mgc.cAanvragen)"
                        >
                            Aanvragen
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            color="primary"
                            [magic]="mgc.cGebruikersBeheer"
                        >
                            Gebruikers beheer
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            [magic]="mgc.cUitgiftepunt"
                        >
                            Uitgiftepunt
                        </a>
                    </li>
                </ul>
                    <li id="logout">
                        <button
                            mat-raised-button
                            id="btn-logout"
                            color="primary"
                            [magic]="mgc.cLogUit"
                        >
                            Log uit
                        </button>
                    </li>                
            </div>
        </nav>
        <div>
            <mat-card>
                <magic-subform [magic]="mgc.MenuForm">
                </magic-subform>
            </mat-card>
        </div>
    </div>
</div>