<div 
    novalidate 
    [formGroup]="screenFormGroup"
    [magic]="mgc.NieuweGebruiker">

            <div class="col-lg-6">

                <!--Voornaam-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label10) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.voornaam"
                        [formControlName]="mgc.voornaam"
                        mgformat
                    />
                    <mgError [magic]=mgc.voornaam> </mgError>
                </mat-form-field>

                <!--achternaam-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label11) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.achternaam"
                        [formControlName]="mgc.achternaam"
                        mgformat
                    />
                    <mgError [magic]=mgc.achternaam> </mgError>
                </mat-form-field>

                <!--email-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label13) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.email"
                        [formControlName]="mgc.email"
                        mgformat
                    />
                    <mgError [magic]=mgc.email> </mgError>
                </mat-form-field>

                <!--telefoon-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label14) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.telefoon"
                        [formControlName]="mgc.telefoon"
                        mgformat
                    />
                    <mgError [magic]=mgc.telefoon> </mgError>
                </mat-form-field>

                <!--ComboBox role-->
                <mat-form-field>
                    <mat-label>Role</mat-label>
                    <mat-select
                            [magic]="mgc.role"
                            [formControlName]="mgc.role"
                            required
                        >
                            <mat-option
                                *ngFor="let o of mg.getItemListValues(mgc.role);"
                                [value]="o.index"
                            >
                                {{o.displayValue}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <!--Buttons-->
            <div class="col-6">
                <button class="mr-2"
                    mat-button
                    matSuffix
                    mat-raised-button
                    color="primary"
                    [magic]="mgc.Opslaan">
                    <mat-icon>save</mat-icon>
                </button>

                <button
                    mat-button
                    matSuffix
                    mat-raised-button
                    color="primary"
                    [magic]="mgc.Annuleer">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
</div>