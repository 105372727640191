import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./DownloadDocumentenClient.mg.controls.g";
import { MgDisplayedColumns } from "./DownloadDocumentenClient.mg.controls.g";


import { BaseMatTableMagicComponent, matMagicProviders } from "@magic-xpa/angular-material-core";


import { MagicModalInterface } from "@magic-xpa/angular";

@Component({
    selector: 'mga-DownloadDocumentenClient',
    providers: [...matMagicProviders],
    templateUrl: './DownloadDocumentenClient.component.html'
})
export class DownloadDocumentenClient extends BaseMatTableMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgcp = MgCustomProperties;
    mgfc: MgFormControlsAccessor;
    mgdp = MgDisplayedColumns;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }

  fileText: string | ArrayBuffer;
  fileUpload(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event.srcElement.files[0]);
    const i = event.srcElement.files[0].type.length;
    const num = 5 + i + 8;
    const me = this;
    reader.onload = function() {
      me.fileText = reader.result;
      // Updating the Magic Variable with the selected image's base64 representation
      me.mg.setValueToControl('Blob', me.fileText.slice(num));
      // Updating the FileName into the Magic Variable
      me.mg.setValueToControl('vFileName', event.srcElement.files[0].name);
      me.mg.simulateClick('pb_HiddenSave');
    }
  }

    private static readonly formName: string = "DownloadDocumentenClient";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return DownloadDocumentenClient.x;
    }
    Y() {
        return DownloadDocumentenClient.y;
    }
    Width(): string {
        return DownloadDocumentenClient.width;
    }
    Height(): string {
        return DownloadDocumentenClient.height;
    }
    IsCenteredToWindow() {
        return DownloadDocumentenClient.isCenteredToWindow;
    }
    FormName() {
        return DownloadDocumentenClient.formName;
    }
    ShowTitleBar() {
        return DownloadDocumentenClient.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return DownloadDocumentenClient.shouldCloseOnBackgroundClick;
    }
    displayedColumns = this.mgdp;
}
