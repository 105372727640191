<div
    novalidate
    [formGroup]="screenFormGroup"
    [magic]="mgc.UitgiftepuntBeheer"
>
    <div class="col-6">
        <mat-form-field>
                <input
                    matInput
                    [magic]="mgc.cZoekveld"
                    [formControlName]="mgc.cZoekveld"
                    mgFormat
                >
                <mgError [magic]=mgc.cZoekveld> </mgError>
                <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    [magic]="mgc.cZoek">
                    <mat-icon>search</mat-icon>
                </button>
        </mat-form-field>            
    </div>

    <div>
        <mat-card>
            <magic-subform [magic]="mgc.cUitgifte">
            </magic-subform>
        </mat-card>
    </div>
</div>