<div novalidate [formGroup]="screenFormGroup">
    <div [magic]="mgc.Aanvragen">
        <div class="table-container">
            <table 
                mat-table 
                [dataSource]="dataSource" 
                matSort matSortDisableClear
                (matSortChange)="tableService.sortData($event)">
                <!-- Table Columns go here -->

                <!--Col_Status-->
                <ng-container 
                    [magic]="mgc.Col_Status" 
                    [matColumnDef]="mgc.Col_Status">
                    <th 
                        mat-header-cell 
                        *matHeaderCellDef 
                        mat-sort-header
                        >
                        {{mg.getText(mgc.Col_Status)}}
                    </th>

                    <td 
                        mat-cell 
                        *matCellDef="let row" 
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">
                            <!-- Label -->
                            <ng-container 
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container 
                                    [magic]="mgc.cStatus" 
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.cStatus, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--clm_AanvraagDatum-->
                <ng-container
                    [magic]="mgc.clm_AanvraagDatum"
                    [matColumnDef]="mgc.clm_AanvraagDatum"
                >
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                    >
                        {{mg.getText(mgc.clm_AanvraagDatum)}}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                    >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Label -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                            >
                                <ng-container
                                    [magic]="mgc.Clients_AanvraagDatum"
                                    [rowId]="row.rowId"
                                >
                                    {{mg.getValue(mgc.Clients_AanvraagDatum, row.rowId) | date: 'dd-MM-yyyy'}}

                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--cColumReferentienr-->
                <ng-container 
                    [magic]="mgc.cColumReferentienr" 
                    [matColumnDef]="mgc.cColumReferentienr"
                    >
                    <th 
                        mat-header-cell 
                        *matHeaderCellDef 
                        mat-sort-header
                        >
                        {{mg.getText(mgc.cColumReferentienr)}}
                    </th>
                    <td 
                        mat-cell 
                        *matCellDef="let row" 
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container 
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input 
                                        matInput 
                                        [magic]="mgc.referentienummer"
                                        [placeholder]="mg.getPlaceholder(mgc.referentienummer)"
                                        [formControlName]="mgc.referentienummer" mgFormat>
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.referentienummer"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container 
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container 
                                    [magic]="mgc.referentienummer" 
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.referentienummer, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--Col_Achternaam-->
                <ng-container 
                [magic]="mgc.Col_Achternaam" 
                [matColumnDef]="mgc.Col_Achternaam"
                >
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header
                    >
                    {{mg.getText(mgc.Col_Achternaam)}}
                </th>
                <td 
                    mat-cell 
                    *matCellDef="let row" 
                    magicMark="magicTableRowContainer"
                    >
                    <ng-container *ngIf="mg.ifRowCreated(row)">

                        <!-- Edit Section -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="mg.isRowInRowEditing(row)"
                            >
                            <mat-form-field>
                                <input 
                                    matInput 
                                    [magic]="mgc.achternaam"
                                    [placeholder]="mg.getPlaceholder(mgc.achternaam)"
                                    [formControlName]="mgc.achternaam" mgFormat>
                            </mat-form-field>
                            <mgError class="errors" [magic]="mgc.achternaam"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.achternaam" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getValue(mgc.achternaam, row.rowId)}}
                            </ng-container>
                        </ng-container>

                    </ng-container>
                </td>
                </ng-container>
                

                <!--cColumGeboortedatum-->
                <ng-container 
                [magic]="mgc.cColumGeboortedatum" 
                [matColumnDef]="mgc.cColumGeboortedatum"
                >
                <th 
                    mat-header-cell 
                    *matHeaderCellDef 
                    mat-sort-header
                    >
                    {{mg.getText(mgc.cColumGeboortedatum)}}
                </th>
                <td 
                    mat-cell 
                    *matCellDef="let row" 
                    magicMark="magicTableRowContainer"
                    >
                    <ng-container *ngIf="mg.ifRowCreated(row)">

                        <!-- Edit Section -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="mg.isRowInRowEditing(row)"
                            >
                            <mat-form-field>
                                <input 
                                    matInput 
                                    [magic]="mgc.geboorteDatum"
                                    [placeholder]="mg.getPlaceholder(mgc.geboorteDatum)"
                                    [formControlName]="mgc.geboorteDatum" mgFormat>
                            </mat-form-field>
                            <mgError class="errors" [magic]="mgc.geboorteDatum"></mgError>
                        </ng-container>

                        <!-- Label -->
                        <ng-container 
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            *ngIf="!mg.isRowInRowEditing(row)"
                            >
                            <ng-container 
                                [magic]="mgc.geboorteDatum" 
                                [rowId]="row.rowId"
                                >
                                {{mg.getValue(mgc.geboorteDatum, row.rowId) | date: 'dd-MM-yyyy'}}

                            </ng-container>
                        </ng-container>

                    </ng-container>
                </td>
                </ng-container>


                
                        <!-- Buttons -->
                        <ng-container 
                        [magic]="mgc.Col_BTN" 
                        [matColumnDef]="mgc.Col_BTN">
    
                        <th 
                            mat-header-cell 
                            *matHeaderCellDef ></th>
                        <td 
                            mat-cell 
                            *matCellDef="let row" 
                            magicMark="magicTableRowContainer">
    
                            <ng-container *ngIf="mg.ifRowCreated(row)">
                                <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                    <button 
                                        mat-button
                                        matSuffix
                                        mat-icon-button
                                        [magic]="mgc.cMuteren" 
                                        [rowId]="row.rowId">
                                        <mat-icon>file_open</mat-icon>
                                    </button>            
                                </ng-container>

                                <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                    <button
                                    tabindex="-1"
                                    mat-button
                                    matSuffix
                                    mat-icon-button
                                    [magic]="mgc.pb_Delete" 
                                    [rowId]="row.rowId">
                                    <mat-icon>delete</mat-icon>
                                    </button>                                
                                </ng-container>                          
                                
                            </ng-container>
                        </td>
                        </ng-container>

                <tr 
                    mat-header-row 
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    [class.selected]="selection.isSelected(row)"
                    [class.editmode]="row ? mg.isRowInRowEditing(row) : null"
                    mat-row *matRowDef="let row; columns: displayedColumns" 
                    [magicRow]="row?.rowId"></tr>
            </table>
        </div>

        <mat-paginator 
            #paginator 
            [pageSize]="30" 
            [pageSizeOptions]="[15, 30, 50]"
            (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>

    </div>
</div>
