<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        [magic]="mgc.Main"
    >
        <div>
            <router-outlet [magic]="mgc.cMainFrameWork">
            </router-outlet>
        </div>
    </div>
</div>