import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Gebruikers = "Gebruikers",
        Table1 = "Table1",
        Column4 = "Column4",
        achternaam = "achternaam",
        Column2 = "Column2",
        voornaam = "voornaam",
        Column7 = "Column7",
        organisatie = "organisatie",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column4',
        'Column2',
        'Column7',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get achternaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.achternaam);
    }

    get voornaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.voornaam);
    }

    get organisatie(): FormControl {
        return this.getTableChildFormControl(MgControlName.organisatie);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}