import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    UserManagement = "UserManagement",
        cZoekveld = "cZoekveld",
        cZoek = "cZoek",
        // cToevoegen = "cToevoegen",
        cGebruikers = "cGebruikers",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cZoekveld(): FormControl {
        return this.fg.controls[MgControlName.cZoekveld] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}