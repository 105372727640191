<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.NieuweGebruiker"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label9"
                class="lable_overflow"
            >
                user_id:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        [magic]="mgc.user_id"
                        [formControlName]="mgc.user_id"
                        mgFormat
                    >
                    <mgError [magic]=mgc.user_id> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label10"
                class="lable_overflow"
            >
                voornaam:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.voornaam"
                        [formControlName]="mgc.voornaam"
                        mgFormat
                    >
                    <mgError [magic]=mgc.voornaam> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label11"
                class="lable_overflow"
            >
                achternaam:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.achternaam"
                        [formControlName]="mgc.achternaam"
                        mgFormat
                    >
                    <mgError [magic]=mgc.achternaam> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label12"
                class="lable_overflow"
            >
                organisatie:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.organisatie"
                        [formControlName]="mgc.organisatie"
                        mgFormat
                    >
                    <mgError [magic]=mgc.organisatie> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label13"
                class="lable_overflow"
            >
                email:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.email"
                        [formControlName]="mgc.email"
                        mgFormat
                    >
                    <mgError [magic]=mgc.email> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label14"
                class="lable_overflow"
            >
                telefoon:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.telefoon"
                        [formControlName]="mgc.telefoon"
                        mgFormat
                    >
                    <mgError [magic]=mgc.telefoon> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label15"
                class="lable_overflow"
            >
                role:
            </label>
            <div>
                <mat-form-field [matTooltip]="mg.getTitle(mgc.role)">
                    <mat-select
                        [magic]="mgc.role"
                        [formControlName]="mgc.role"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.role);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Opslaan"
            >
                Opslaan
            </button>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Annuleer"
            >
                Annuleer
            </button>
        </div>
    </div>
</div>