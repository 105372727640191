<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.EmailTemplates"
    >
        <div style="display: flex; flex-direction: row">
            <div class="example-container mat-elevation-z8 Table1TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.cNaam"
                        [matColumnDef]="mgc.cNaam"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            Naam
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.Naam"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.Naam"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.Naam
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.Naam"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.Naam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column4"
                        [matColumnDef]="mgc.Column4"
                    >
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="!mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.EnterRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Edit
                                </button>
                            </div>
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.SaveRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Save
                                </button>
                            </div>
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.CancelRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Cancel
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>