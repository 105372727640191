<div novalidate [formGroup]="screenFormGroup">
    <div class="mb-2" align="end">
        <button
            matSuffix
            color="primary"
            mat-raised-button
            [style.visibility]="mg.getVisible(mgc.pb_Toevoegen)"
            [magic]="mgc.pb_Toevoegen">
            <mat-icon>add</mat-icon> Toevoegen
        </button>
    </div>
    <div [magic]="mgc.OverigUitgaven">
        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort matSortDisableClear
                (matSortChange)="tableService.sortData($event)">
                <!-- Table Columns go here -->

                <!--ColAflossing-->
                <ng-container
                    [magic]="mgc.ColAflossing"
                    [matColumnDef]="mgc.ColAflossing">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        >
                        {{mg.getText(mgc.ColAflossing)}}
                    </th>

                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input
                                        matInput
                                        [magic]="mgc.cAflossing"
                                        [placeholder]="mg.getPlaceholder(mgc.cAflossing)"
                                        [formControlName]="mgc.cAflossing"
                                        mgFormat>
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.cAflossing"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container
                                    [magic]="mgc.cAflossing"
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.cAflossing, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--ColUitlegAflossing-->
                <ng-container
                    [magic]="mgc.ColUitlegAflossing"
                    [matColumnDef]="mgc.ColUitlegAflossing"
                    >
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        >
                        {{mg.getText(mgc.ColUitlegAflossing)}}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input
                                        matInput
                                        [magic]="mgc.cNaamAflossing"
                                        [placeholder]="mg.getPlaceholder(mgc.cNaamAflossing)"
                                        [formControlName]="mgc.cNaamAflossing" mgFormat>
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.cNaamAflossing"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container
                                    [magic]="mgc.cNaamAflossing"
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.cNaamAflossing, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>




                <!-- Buttons -->
                <ng-container
                    [magic]="mgc.ColBTN"
                    [matColumnDef]="mgc.ColBTN">

                    <th
                        mat-header-cell
                        *matHeaderCellDef ></th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer">

                        <ng-container *ngIf="mg.ifRowCreated(row)">
                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button
                                tabindex="-1"
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.pb_Delete"
                                [style.visibility]="mg.getVisible(mgc.pb_Delete, row.rowId)"
                                [rowId]="row.rowId">
                                <mat-icon>delete</mat-icon> Verwijderen
                                </button>
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.SaveRowEditing"
                                [rowId]="row.rowId"
                                [style.visibility]="mg.getVisible(mgc.SaveRowEditing, row.rowId)">
                                <mat-icon>save</mat-icon> Opslaan
                                </button>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>


                <!--Buttons2-->
                <ng-container
                    [magic]="mgc.ColBTN2"
                    [matColumnDef]="mgc.ColBTN2">

                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header></th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer">

                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.EnterRowEditing"
                                [rowId]="row.rowId"
                                [style.visibility]="mg.getVisible(mgc.EnterRowEditing, row.rowId)">
                                <mat-icon>edit</mat-icon> Wijzigen
                                </button>
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.CancelRowEditing"
                                [rowId]="row.rowId"
                                [style.visibility]="mg.getVisible(mgc.CancelRowEditing, row.rowId)">
                                <mat-icon>cancel</mat-icon> Annuleren
                                </button>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    [ngClass]="{ 'selected': selection.isSelected(row)}"
                    [class.editmode]="mg.isRowInRowEditing(row)"
                    mat-row *matRowDef="let row; columns: displayedColumns"
                    [magicRow]="row?.rowId"></tr>
            </table>
        </div>

        <mat-paginator
            #paginator
            [pageSize]="30"
            (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>

    </div>
</div>
