import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    UserManagement = "UserManagement",
        vZoekveld = "vZoekveld",
        Zoek = "Zoek",
        Toevoegen = "Toevoegen",
        Gebruikers = "Gebruikers",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get vZoekveld(): FormControl {
        return this.fg.controls[MgControlName.vZoekveld] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}