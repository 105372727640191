<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        [magic]="mgc.EditInstellingen"
    >
            <div class="col-6">
                <!--Voornaam-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label2) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.voornaam"
                        [formControlName]="mgc.voornaam"
                        mgformat
                    />
                    <mgError [magic]=mgc.voornaam> </mgError>
                </mat-form-field>
            </div>

            <div class="col-6">
                <!--achternaam-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label4) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.achternaam"
                        [formControlName]="mgc.achternaam"
                        mgformat
                    />
                    <mgError [magic]=mgc.achternaam> </mgError>
                </mat-form-field>
            </div>

            <div class="col-6">
                <!--email-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label6) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.email"
                        [formControlName]="mgc.email"
                        mgformat
                    />
                    <mgError [magic]=mgc.email> </mgError>
                </mat-form-field>
            </div>

            <div class="col-6">
                <!--telefoon-->
                <mat-form-field>
                    <mat-label>{{ mg.getText(mgc.Label8) }}</mat-label>
                    <input
                        matInput
                        [magic]="mgc.telefoon"
                        [formControlName]="mgc.telefoon"
                        mgformat
                    />
                    <mgError [magic]=mgc.telefoon> </mgError>
                </mat-form-field>
            </div>

         <!--Buttons-->
        <div>           

            <button class="mr-2 ml-3"
                mat-button
                matSuffix
                mat-raised-button
                color="primary"
                [magic]="mgc.Opslaan">
                <mat-icon>save</mat-icon>
            </button>

            <button class="mr-2"
                mat-button
                matSuffix
                mat-raised-button
                color="primary"
                [magic]="mgc.cAnnuleren">
                <mat-icon>cancel</mat-icon>
            </button>

            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.cChangePWD">
                <mat-icon>key</mat-icon>
            </button>
            
        </div>

    </div>
</div>