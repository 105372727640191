import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Children = "Children",
        Table1 = "Table1",
        Column2 = "Column2",
        roepnaam = "roepnaam",
        Column4 = "Column4",
        voornaam = "voornaam",
        Column6 = "Column6",
        achternaam = "achternaam",
        Column8 = "Column8",
        geboorteDatum = "geboorteDatum",
        Column10 = "Column10",
        geslacht = "geslacht",
        Column12 = "Column12",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        Button16 = "Button16",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column2',
        'Column4',
        'Column6',
        'Column8',
        'Column10',
        'Column12',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get roepnaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.roepnaam);
    }

    get voornaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.voornaam);
    }

    get achternaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.achternaam);
    }

    get geboorteDatum(): FormControl {
        return this.getTableChildFormControl(MgControlName.geboorteDatum);
    }

    get geslacht(): FormControl {
        return this.getTableChildFormControl(MgControlName.geslacht);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}