import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Login = "Login",
        Label2 = "Label2",
        Email = "Email",
        Label4 = "Label4",
        Wachtwoord = "Wachtwoord",
        LoginButton = "LoginButton",
        bVergeten = "bVergeten",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Email(): FormControl {
        return this.fg.controls[MgControlName.Email] as FormControl;
    }

    get Wachtwoord(): FormControl {
        return this.fg.controls[MgControlName.Wachtwoord] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}