import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    UploadFiles = "UploadFiles",
        Blob = "Blob",
        vFilename = "vFilename",
        cSelecteerbestand = "cSelecteerbestand",
        cSave = "cSave",
        cAnnuleer = "cAnnuleer",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Blob(): FormControl {
        return this.fg.controls[MgControlName.Blob] as FormControl;
    }

    get vFilename(): FormControl {
        return this.fg.controls[MgControlName.vFilename] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}