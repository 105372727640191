<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.Children"
    >
        <div style="display: flex; flex-direction: row">
            <div class="example-container mat-elevation-z8 Table1TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.Column2"
                        [matColumnDef]="mgc.Column2"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            roepnaam
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.roepnaam"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.roepnaam"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.roepnaam
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.roepnaam"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.roepnaam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column4"
                        [matColumnDef]="mgc.Column4"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            voornaam
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.voornaam"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.voornaam"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.voornaam
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.voornaam"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.voornaam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column6"
                        [matColumnDef]="mgc.Column6"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            achternaam
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.achternaam"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.achternaam"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.achternaam
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.achternaam"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.achternaam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column8"
                        [matColumnDef]="mgc.Column8"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            geboorteDatum
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field
                                        [magic]="mgc.geboorteDatum"
                                        [rowId]="row.rowId"
                                        [eventsOnly]=true
                                        *ngIf="mg.isRowInRowEditing(row)"
                                    >
                                        <div style="display: flex; flex-direction: row">
                                            <input
                                                matInput
                                                [matDatepicker]="geboorteDatum"
                                                [magic]="mgc.geboorteDatum"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.geboorteDatum"
                                                mgFormat
                                            >
                                            <mat-datepicker-toggle
                                                matSuffix
                                                [for]="geboorteDatum"
                                                *ngIf="mg.checkIsReadOnly(mgc.geboorteDatum) &&  mg.isRowInRowEditing(row)"
                                            >
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #geboorteDatum></mat-datepicker>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.geboorteDatum"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.geboorteDatum, row.rowId)
| magicDate : mgc.geboorteDatum}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10"
                        [matColumnDef]="mgc.Column10"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            geslacht
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div [formGroup]="mg.getFormGroupByRow(row.rowId)">
                                    <div *ngIf="mg.isRowInRowEditing(row)">
                                        <mat-form-field>
                                            <mat-select
                                                [magic]="mgc.geslacht"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.geslacht"
                                            >
                                                <mat-option
                                                    *ngFor="let o of mg.getItemListValues(mgc.geslacht, row.rowId);"
                                                    [value]="o.index"
                                                >
                                                    {{o.displayValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="!mg.isRowInRowEditing(row)">
                                        <label
                                            [magic]="mgc.geslacht"
                                            [rowId]="row.rowId"
                                        >
                                            {{ mg.getDisplayValue(mgc.geslacht, row.rowId) }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column12"
                        [matColumnDef]="mgc.Column12"
                    >
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="!mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.EnterRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Wijzigen
                                </button>
                            </div>
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.SaveRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Opslaan
                                </button>
                            </div>
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.CancelRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Annuleren
                                </button>
                            </div>
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    color="primary"
                                    [magic]="mgc.Button16"
                                    [rowId]="row.rowId"
                                >
                                    Toevoegen
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>