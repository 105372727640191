<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.UploadFiles"
    >
        <div style="display: flex; flex-direction: row">
            <mat-form-field [style.visibility]="mg.getVisible(mgc.Blob)">
                <div>
                    <input
                        matInput
                        [magic]="mgc.Blob"
                        [formControlName]="mgc.Blob"
                    >
                    <mgError [magic]=mgc.Blob> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.vFilename"
                        [formControlName]="mgc.vFilename"
                        mgFormat
                    >
                    <mgError [magic]=mgc.vFilename> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <button 
            mat-raised-button
            color="primary"
            [magic]="mgc.cSelecteerbestand"
             (change)="fileUpload($event)">
                <label class="pt-3 pb-3 pl-2 pr-2" style="display:inline;">
                    <i class="fa fa-folder-open"></i> Selecteer bestand
                    <input
                        type="file"
                        style="display: none"
                    />
                </label>
            </button>
            
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.cSave"
                [disabled]="mg.isDisabled(mgc.cSave )"
            >
                Opslaan
            </button>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.cAnnuleer"
            >
                Annuleer
            </button>
        </div>
    </div>
</div>