<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.UserManagement"
    >
        <div style="display: flex; flex-direction: row">
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.vZoekveld"
                        [formControlName]="mgc.vZoekveld"
                        mgFormat
                    >
                    <mgError [magic]=mgc.vZoekveld> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Zoek"
            >
                Zoek
            </button>
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.Toevoegen"
            >
                +
            </button>
        </div>
        <div style="display: flex; flex-direction: row">
            <mat-card>
                <magic-subform [magic]="mgc.Gebruikers">
                </magic-subform>
            </mat-card>
        </div>
    </div>
</div>