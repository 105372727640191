import {
    Component
} from '@angular/core';

import {
  FormControl,
  FormGroup
} from '@angular/forms';
import {
    MgFormControlsAccessor,
    MgControlName
} from "./FileTransfer.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-FileTransfer',
    providers: [...magicProviders],
    templateUrl: './FileTransfer.component.html'
})
export class FileTransfer extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Download";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "0px";
    private static readonly height: string = "0px";
    private static readonly isCenteredToWindow: boolean = false;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return FileTransfer.x;
    }
    Y() {
        return FileTransfer.y;
    }
    Width(): string {
        return FileTransfer.width;
    }
    Height(): string {
        return FileTransfer.height;
    }
    IsCenteredToWindow() {
        return FileTransfer.isCenteredToWindow;
    }
    FormName() {
        return FileTransfer.formName;
    }
    ShowTitleBar() {
        return FileTransfer.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return FileTransfer.shouldCloseOnBackgroundClick;
    }
	 ngOnInit() {
    super.ngOnInit();
    const group: FormGroup = this.screenFormGroup;
    (group.controls.Blob64AtServer as FormControl).registerOnChange(this.OnChange.bind(this)
    );
  }
  OnChange() {
    if (
      this.mg.getValue('Blob64AtServer') !== undefined &&
      this.mg.getValue('Blob64AtServer') !== null
    )
    {
      this.uhgetfile();
    }
  }

  uhgetfile() {

    const base64 = this.mg.getValue('Blob64AtServer');


    if (base64 !== undefined || base64 !== null) {

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const binLb2 = new window.Blob([byteArray], { type: 'application/pdf' });

      if(this.mg.getValue('vOpenOrDownload') === 'D'){
        if (binLb2.size !== 0) {
          const downloadLink = document.createElement('a');
          const fileName = this.mg.getValue('vfilename');
          const linkSource = window.URL.createObjectURL(binLb2);

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          window.URL.revokeObjectURL(linkSource);
        }
      } else {
       if (binLb2.size !== 0) {
         const fileUrl = URL.createObjectURL(binLb2);
         window.open(fileUrl);
       }
      }
    }
  }
}
