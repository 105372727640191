<div class="container">
    <div novalidate [formGroup]="screenFormGroup">
        <div [magic]="mgc.Aanvraagformulier">
            <div class="row">
                <button class="btnForm" id="loginForm" mat-raised-button color="primary" [magic]="mgc.cLoginForm">
                    Login
                </button>
            </div>

            <div class="row categoryBlock">
                <div class="col-lg-12">
                    <h3>Aanmelding voedselpakket</h3>                    
                </div>
            </div>


            <div class="row categoryBlock">
                <div class="col-lg-4">
                    <h5>Gegevens afnemer</h5>
                    <label [magic]="mgc.lVoornaam" class="lable_overflow mgFont1">
                        Voornaam
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cVoornaam" [formControlName]="mgc.cVoornaam" mgFormat required>
                            <mgError [magic]=mgc.cVoornaam> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lAchternaam" class="lable_overflow mgFont1">
                        Achternaam
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cAchternaam" [formControlName]="mgc.cAchternaam" mgFormat
                                required>
                            <mgError [magic]=mgc.cAchternaam> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lMeisjesnaam" class="lable_overflow mgFont1">
                        Meisjesnaam
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cMeisjesnaam" [formControlName]="mgc.cMeisjesnaam" mgFormat>
                            <mgError [magic]=mgc.cMeisjesnaam> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lGeboorteDatum" class="lable_overflow mgFont1">
                        Geboorte Datum
                    </label>
                    <mat-form-field [magic]="mgc.cGeboorteDatum" [eventsOnly]=true>
                        <input matInput [matDatepicker]="cGeboorteDatum" [magic]="mgc.cGeboorteDatum"
                            [formControlName]="mgc.cGeboorteDatum" mgFormat>
                        <mat-datepicker-toggle matSuffix [for]="cGeboorteDatum"
                            *ngIf="mg.checkIsReadOnly(mgc.cGeboorteDatum)">
                        </mat-datepicker-toggle>
                        <mat-datepicker #cGeboorteDatum></mat-datepicker>
                    </mat-form-field>
                    <br>
                    <mat-form-field>
                        <label class="lable_overflow mgFont1">Geslacht</label>
                        <mat-select [magic]="mgc.cGeslacht" [formControlName]="mgc.cGeslacht" required>
                            <mat-option *ngFor="let o of mg.getItemListValues(mgc.cGeslacht);" [value]="o.index">
                                {{o.displayValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mgError [magic]=mgc.cGeslacht> </mgError>
                    <label [magic]="mgc.lAdres" class="lable_overflow mgFont1">
                        Adres
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cAdresAanvrager" [formControlName]="mgc.cAdresAanvrager"
                                mgFormat required>
                            <mgError [magic]=mgc.cAdresAanvrager> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lPostcode" class="lable_overflow mgFont1">
                        Postcode
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cPostcode" [formControlName]="mgc.cPostcode" mgFormat required>
                            <mgError [magic]=mgc.cPostcode> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lPlaats" class="lable_overflow mgFont1">
                        Plaats
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cPlaats" [formControlName]="mgc.cPlaats" mgFormat required>
                            <mgError [magic]=mgc.cPlaats> </mgError>
                        </div>
                    </mat-form-field>
                    
                </div>
                <div class="col-lg-4">
                    <h5>Gegevens Partner</h5>
                    <label [magic]="mgc.lVoornaamPartner" class="lable_overflow mgFont1">
                        Voornaam Partner
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cVoornaamPartner" [formControlName]="mgc.cVoornaamPartner"
                                mgFormat>
                            <mgError [magic]=mgc.cVoornaamPartner> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lAchternaamPartner" class="lable_overflow mgFont1">
                        Achternaam Partner
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cAchternaamPartner" [formControlName]="mgc.cAchternaamPartner"
                                mgFormat>
                            <mgError [magic]=mgc.cAchternaamPartner> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lMeisjesnaamPartner" class="lable_overflow mgFont1">
                        Meisjesnaam Partner
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cMeisjesnaamPartner"
                                [formControlName]="mgc.cMeisjesnaamPartner" mgFormat>
                            <mgError [magic]=mgc.cMeisjesnaamPartner> </mgError>
                        </div>
                    </mat-form-field>
                    <br>
                    <mat-form-field>
                        <label class="lable_overflow mgFont1">Geslacht partner</label>
                        <mat-select [magic]="mgc.cGeslachtPartner" [formControlName]="mgc.cGeslachtPartner">
                            <mat-option *ngFor="let o of mg.getItemListValues(mgc.cGeslachtPartner);" [value]="o.index">
                                {{o.displayValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <label [magic]="mgc.lEmail" [matTooltip]="mg.getTitle(mgc.lEmail)" class="lable_overflow mgFont1">
                        Email
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cEmail" [formControlName]="mgc.cEmail" mgFormat required>
                            <mgError [magic]=mgc.cEmail> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lTelefoon" class="lable_overflow mgFont1">
                        Telefoon
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cTelefoon" [formControlName]="mgc.cTelefoon" mgFormat required>
                            <mgError [magic]=mgc.cTelefoon> </mgError>
                        </div>
                    </mat-form-field>

                    <h5>Voorkeur uitgiftepunt</h5>
                    <label [magic]="mgc.lAdresUitgifte" class="lable_overflow mgFont1">
                        Adres Uitgifte
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cadresUitgifte" [formControlName]="mgc.cadresUitgifte"
                                mgFormat>
                            <mgError [magic]=mgc.cadresUitgifte> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.IPlaatsUitgifte" class="lable_overflow mgFont1">
                        Plaats Uitgifte
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cplaatsUitgifte" [formControlName]="mgc.cplaatsUitgifte"
                                mgFormat>
                            <mgError [magic]=mgc.cplaatsUitgifte> </mgError>
                        </div>
                    </mat-form-field>
                    
                </div>
                <div class="col-lg-4">
                    <h5>Gegevens aanvragende instantie</h5>

                    <label [magic]="mgc.lNaamOrg" class="lable_overflow mgFont1">
                        Naam organisatie
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cNaam_Org" [formControlName]="mgc.cNaam_Org"
                                mgFormat>
                            <mgError [magic]=mgc.cNaam_Org> </mgError>
                        </div>
                    </mat-form-field>

                    <label [magic]="mgc.lEmailOrg" class="lable_overflow mgFont1">
                        Email Organisatie
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cEmail_Org" [formControlName]="mgc.cEmail_Org"
                                mgFormat>
                            <mgError [magic]=mgc.cEmail_Org> </mgError>
                        </div>
                    </mat-form-field>

                    <label [magic]="mgc.lTelefoonOrg" class="lable_overflow mgFont1">
                        Telefoonnummer
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cTelefoonnummer_Org" [formControlName]="mgc.cTelefoonnummer_Org"
                                mgFormat>
                            <mgError [magic]=mgc.cTelefoonnummer_Org> </mgError>
                        </div>
                    </mat-form-field>

                    <label [magic]="mgc.lReferentienummer" class="lable_overflow mgFont1">
                        Referentienummer
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cReferentienummer" [formControlName]="mgc.cReferentienummer"
                                mgFormat>
                            <mgError [magic]=mgc.cReferentienummer> </mgError>
                        </div>
                    </mat-form-field>

                </div>
            </div>

            <div class="row categoryBlock" id="blockChildren">
                <h5>Voeg hieronder uw kinderen toe</h5>
                <mat-card>
                    <magic-subform [magic]="mgc.cChildren">
                    </magic-subform>
                </mat-card>                    
            </div>
            

            <div class="row categoryBlock">
                <div class="col-lg-6">
                    <label [magic]="mgc.lBewindvoering" class="lable_overflow">
                        Is er sprake van financiële bewindvoering? Dan het WEEKgeld graag hier invullen:
                    </label>
                    <label [magic]="mgc.Label127" class="lable_overflow">
                        Bij weekgeld BUDGETPLAN meesturen
                    </label>
                </div>
                <div class="col-lg-6">
                    <label [magic]="mgc.lWeekgeld" class="lable_overflow mgFont1">
                        weekgeld
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cWeekgeld" [formControlName]="mgc.cWeekgeld"
                                mgFormat>
                            <mgError [magic]=mgc.cWeekgeld> </mgError>
                        </div>
                    </mat-form-field>
                    <button mat-raised-button color="primary" [magic]="mgc.ShowImages">
                        ...
                    </button>
                </div>
            </div>

            <div class="row categoryBlock">
                <div class="col-lg-4">
                    <h5>Inkomsten</h5>
                    <label [magic]="mgc.lNettoLoon" class="lable_overflow mgFont1">
                        Netto Loon
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cNettoloon" [formControlName]="mgc.cNettoloon"
                                mgFormat>
                            <mgError [magic]=mgc.cNettoloon> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lUiterking" class="lable_overflow mgFont1">
                        Uitkering
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cUitkering" [formControlName]="mgc.cUitkering"
                                mgFormat>
                            <mgError [magic]=mgc.cUitkering> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lNettoLoonPartner" class="lable_overflow mgFont1">
                        Netto Loon Partner
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cNettoPartner" [formControlName]="mgc.cNettoPartner"
                                mgFormat>
                            <mgError [magic]=mgc.cNettoPartner> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lUitkeringPartner" class="lable_overflow mgFont1">
                        Uitkering Partner
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cUitkeringPartner"
                                [formControlName]="mgc.cUitkeringPartner" mgFormat>
                            <mgError [magic]=mgc.cUitkeringPartner> </mgError>
                        </div>
                    </mat-form-field>
                    
                    <label [magic]="mgc.lBelastingTeruggave" class="lable_overflow mgFont1">
                        Belasting Teruggave
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cBelastingTeruggave"
                                [formControlName]="mgc.cBelastingTeruggave" mgFormat>
                            <mgError [magic]=mgc.cBelastingTeruggave> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lBijstandDoel" class="lable_overflow mgFont1">
                        Bijstand Doel
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cBijstandDoel" [formControlName]="mgc.cBijstandDoel"
                                mgFormat>
                            <mgError [magic]=mgc.cBijstandDoel> </mgError>
                        </div>
                    </mat-form-field>
                </div>

                <div class="col-lg-4">
                    <label [magic]="mgc.lAlimentatie" class="lable_overflow mgFont1">
                        Alimentatie
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cAlimentatie" [formControlName]="mgc.cAlimentatie"
                                mgFormat>
                            <mgError [magic]=mgc.cAlimentatie> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lHuurToeslag" class="lable_overflow mgFont1">
                        Huur Toeslag
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cHuurToeslag" [formControlName]="mgc.cHuurToeslag"
                                mgFormat>
                            <mgError [magic]=mgc.cHuurToeslag> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lZorgToeslag" class="lable_overflow mgFont1">
                        Zorg Toeslag
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cZorgToeslag" [formControlName]="mgc.cZorgToeslag"
                                mgFormat>
                            <mgError [magic]=mgc.cZorgToeslag> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lKostgeldKind" class="lable_overflow mgFont1">
                        Kostgeld kind
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cKostgeldKind" [formControlName]="mgc.cKostgeldKind"
                                mgFormat>
                            <mgError [magic]=mgc.cKostgeldKind> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lKindgebondenBudget" class="lable_overflow mgFont1">
                        Kindgebonden budget
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cKGB" [formControlName]="mgc.cKGB" mgFormat>
                            <mgError [magic]=mgc.cKGB> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lBijstandBedrag" class="lable_overflow mgFont1">
                        Bijstand Bedrag
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cBijstandBedrag"
                                [formControlName]="mgc.cBijstandBedrag" mgFormat>
                            <mgError [magic]=mgc.cBijstandBedrag> </mgError>
                        </div>
                    </mat-form-field>

                </div>

                <div class="col-lg-4">
                    <h5>Uitgaven</h5>
                    <label [magic]="mgc.lHuurHypotheek" [matTooltip]="mg.getTitle(mgc.lHuurHypotheek)"
                        class="lable_overflow mgFont1">
                        Huur/Hypotheek
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cHuurHypotheek"
                                [formControlName]="mgc.cHuurHypotheek" mgFormat>
                            <mgError [magic]=mgc.cHuurHypotheek> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lEnergieWater" class="lable_overflow mgFont1">
                        Energie + Water
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cEnergieWater" [formControlName]="mgc.cEnergieWater"
                                mgFormat>
                            <mgError [magic]=mgc.cEnergieWater> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lGemWaBelasting" class="lable_overflow mgFont1">
                        Gemeentelijke en waterschapbelasting
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.cBelastingWater"
                                [formControlName]="mgc.cBelastingWater" mgFormat>
                            <mgError [magic]=mgc.cBelastingWater> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.lPersHuisUitgaven" class="lable_overflow mgFont1">
                        Persoonlijke en Huishoudelijke uitgaven
                    </label>
                    <label [magic]="mgc.cPersHuisUitgaven">
                        {{mg.getValue(mgc.cPersHuisUitgaven) }}
                    </label>
                    

                </div>

            </div>

            <div class="row categoryBlock" id="blockAflosssing">
                <h5>Voeg hier uw extra aflossingen toe</h5>
                <mat-card>
                    <magic-subform [magic]="mgc.cOverigeUitgaven">
                    </magic-subform>
                </mat-card>                   
            </div>
            
            
            <div class="row categoryBlock">
                <div class="col-lg-4">
                    <h5>Totaal overzicht</h5>
                    <label [magic]="mgc.lTotaalInkomsten" class="lable_overflow mgFont1">
                        totaal_inkomsten
                    </label>
                    <label [magic]="mgc.totaal_inkomsten">
                        {{mg.getValue(mgc.totaal_inkomsten) }}
                    </label>
                    <label [magic]="mgc.Label137" class="lable_overflow mgFont1">
                        vAantalGezinsleden
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.vAantalGezinsleden"
                                [formControlName]="mgc.vAantalGezinsleden" mgFormat>
                            <mgError [magic]=mgc.vAantalGezinsleden> </mgError>
                        </div>
                    </mat-form-field>
                </div>

                <div class="col-lg-4">
                    <h5>Totaal uitgaven</h5>
                    <label [magic]="mgc.lTotaalUitgaven" class="lable_overflow mgFont1">
                        totaal_uitgaven
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.totaal_uitgaven"
                                [formControlName]="mgc.totaal_uitgaven" mgFormat>
                            <mgError [magic]=mgc.totaal_uitgaven> </mgError>
                        </div>
                    </mat-form-field>
                </div>

                <div class="col-lg-4">
                    <h5>Totaal overzicht</h5>
                    <label [magic]="mgc.lNettoTeBesteden" class="lable_overflow mgFont1">
                        netto_tebesteden
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.netto_tebesteden"
                                [formControlName]="mgc.netto_tebesteden" mgFormat>
                            <mgError [magic]=mgc.netto_tebesteden> </mgError>
                        </div>
                    </mat-form-field>
                    <label [magic]="mgc.Label139" class="lable_overflow mgFont1">
                        vTotaalOverigeUitgaven
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput type='number' [magic]="mgc.vTotaalOverigeUitgaven"
                                [formControlName]="mgc.vTotaalOverigeUitgaven" mgFormat>
                            <mgError [magic]=mgc.vTotaalOverigeUitgaven> </mgError>
                        </div>
                    </mat-form-field>
                </div>

            </div>

            <div class="row categoryBlock">
                <div class="col-lg-6">
                    <label [magic]="mgc.lToelichting2" class="lable_overflow">
                        Geef een toelichting over de persoonlijke situatie van de cliënt.
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cToelichting1" [formControlName]="mgc.cToelichting1" mgFormat>
                            <mgError [magic]=mgc.cToelichting1> </mgError>
                        </div>
                    </mat-form-field>

                </div>
                <div class="col-lg-6">
                    <label [magic]="mgc.lToelichting2" class="lable_overflow">
                        Is de aanvrager ook hulpverlener? Wat wordt er gedaan om de situatie van de klant te verbeteren?
                    </label>
                    <mat-form-field>
                        <div>
                            <input matInput [magic]="mgc.cToelichting2" [formControlName]="mgc.cToelichting2" mgFormat>
                            <mgError [magic]=mgc.cToelichting2> </mgError>
                        </div>
                    </mat-form-field>
                </div>
            </div>

            <div class="row categoryBlock">
                <div class="col-lg-12">
                    <div>
                        <mat-checkbox [magic]="mgc.cbClient" [formControlName]="mgc.cbClient">
                            *Cliënt geeft toestemming om de op dit formulier verstrekte gegevens te verwerken en op te slaan
                            conform de regels in ons privacy-statement
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [magic]="mgc.cbAanvrager" [formControlName]="mgc.cbAanvrager">
                            Cliënt geef toestemming om de gegevens (naam, geslacht en leeftijd) van kinderen van 0 tot 18
                            jaar door te geven aan de Stichting Jarige Job ten behoeve van de verstrekking van een
                            verjaardagscadeautje.
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [magic]="mgc.cbInwoner" [formControlName]="mgc.cbInwoner">
                            *Inwoner van Arnhem? Cliënt geeft toestemming voor het doorgeven van zijn/haar gegevens aan
                            Rijnstad voor het aanbieden van een gratis voorzieningencheck (geldt niet bij Bewindvoering).
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            
            <div class="row rowForm">
                <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cVerzenden">
                    Verzenden
                </button>
                <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cOpslaan">
                    Concept Opslaan
                </button>
                <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cAnnuleer">
                    Annuleer
                </button>
                <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cGoedkeuren">
                    Goedkeuren
                </button>
                <button class="btnForm" mat-raised-button color="primary" [magic]="mgc.cAfkeuren">
                    Afkeuren
                </button>
            </div>
        </div>
    </div>
</div>