import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MagicModule } from '@magic-xpa/angular';
import { MagicAngularMaterialModule } from '@magic-xpa/angular-material-core';
import { MagicGenLibModule } from './magic/magic.gen.lib.module';
import { MagicRoutingModule } from './app.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormField, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

export function patchMatFormField(): void {
  const patchedFormFieldClass = MatFormField.prototype as any;

  patchedFormFieldClass.updateOutlineGapOriginal =
    MatFormField.prototype.updateOutlineGap;
  MatFormField.prototype.updateOutlineGap = function(): void {
    this.updateOutlineGapOriginal();
    const container = this._connectionContainerRef.nativeElement;
    const gapEls = container.querySelectorAll('.mat-form-field-outline-gap');
    gapEls.forEach((gp) => {
      const calculatedGapWidth = +gp.style.width.replace('px', '');
      const gapWidth = calculatedGapWidth / 0.75;
      gp.style.width = `${gapWidth}px`;
    });
  };
}

patchMatFormField();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MagicModule.forRoot(),
    MagicAngularMaterialModule,
    MagicGenLibModule,
    MagicRoutingModule,
    NgbModule,
    MatIconModule
  ],
  providers: [
    {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline', floatLabel: 'always'},
   }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
