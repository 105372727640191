import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    EditInstellingen = "EditInstellingen",
        Label2 = "Label2",
        voornaam = "voornaam",
        Label4 = "Label4",
        achternaam = "achternaam",
        Label6 = "Label6",
        email = "email",
        Label8 = "Label8",
        telefoon = "telefoon",
        cChangePWD = "cChangePWD",
        Opslaan = "Opslaan",
        cAnnuleren = "cAnnuleren",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get voornaam(): FormControl {
        return this.fg.controls[MgControlName.voornaam] as FormControl;
    }

    get achternaam(): FormControl {
        return this.fg.controls[MgControlName.achternaam] as FormControl;
    }

    get email(): FormControl {
        return this.fg.controls[MgControlName.email] as FormControl;
    }

    get telefoon(): FormControl {
        return this.fg.controls[MgControlName.telefoon] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}