import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Children = "Children",
        Table1 = "Table1",
        clmVoornaam = "clmVoornaam",
        voornaam = "voornaam",
        clmAchternaam = "clmAchternaam",
        Achternaam = "Achternaam",
        clmGeboortedatum = "clmGeboortedatum",
        geboorteDatum = "geboorteDatum",
        clmGeslacht = "clmGeslacht",
        geslacht = "geslacht",
        ColBTN = "ColBTN",
        ColBTN2 = "ColBTN2",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        pb_Delete = "pb_Delete",
        pb_Toevoegen = "pb_Toevoegen",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'clmVoornaam',
        'clmAchternaam',
        'clmGeboortedatum',
        'clmGeslacht',
        'ColBTN',
        'ColBTN2',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get voornaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.voornaam);
    }

    get Achternaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.Achternaam);
    }

    get geboorteDatum(): FormControl {
        return this.getTableChildFormControl(MgControlName.geboorteDatum);
    }

    get geslacht(): FormControl {
        return this.getTableChildFormControl(MgControlName.geslacht);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}