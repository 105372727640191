<div novalidate [formGroup]="screenFormGroup">
    <div class="mb-2" align="end">
        <button
            matSuffix
            color="primary"
            mat-raised-button
            [style.visibility]="mg.getVisible(mgc.pb_Toevoegen)"
            [magic]="mgc.pb_Toevoegen">
            <mat-icon>add</mat-icon> Toevoegen
        </button>
    </div>
    <div [magic]="mgc.Children">
        <div class="table-container">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort matSortDisableClear
                (matSortChange)="tableService.sortData($event)">
                <!-- Table Columns go here -->

                <!--Voornaam-->
                <ng-container
                    [magic]="mgc.clmVoornaam"
                    [matColumnDef]="mgc.clmVoornaam">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        >
                        {{mg.getText(mgc.clmVoornaam)}}
                    </th>

                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input
                                        matInput
                                        [magic]="mgc.voornaam"
                                        [placeholder]="mg.getPlaceholder(mgc.voornaam)"
                                        [formControlName]="mgc.voornaam"
                                        mgFormat
                                        />
                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.voornaam"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container
                                    [magic]="mgc.voornaam"
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.voornaam, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <!--Achternaam-->
                <ng-container
                    [magic]="mgc.clmAchternaam"
                    [matColumnDef]="mgc.clmAchternaam"
                    >
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        >
                        {{mg.getText(mgc.clmAchternaam)}}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <!-- Edit Section -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <input
                                        matInput
                                        [magic]="mgc.Achternaam"
                                        [placeholder]="mg.getPlaceholder(mgc.Achternaam)"
                                        [formControlName]="mgc.Achternaam" mgFormat
                                        matTooltip="Ook achternaam invullen indien deze anders is dan de achternaam van afnemer."
                                        matTooltipPosition="right"
                                        />

                                </mat-form-field>
                                <mgError class="errors" [magic]="mgc.Achternaam"></mgError>
                            </ng-container>

                            <!-- Label -->
                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="!mg.isRowInRowEditing(row)"
                                >
                                <ng-container
                                    [magic]="mgc.Achternaam"
                                    [rowId]="row.rowId"
                                    >
                                    {{mg.getValue(mgc.Achternaam, row.rowId)}}
                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>


                <!--Geboortedatum-->
                <ng-container
                    [magic]="mgc.clmGeboortedatum"
                    [matColumnDef]="mgc.clmGeboortedatum">

                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        >
                        {{mg.getText(mgc.clmGeboortedatum)}}
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field
                                    [magic]="mgc.geboorteDatum"
                                    [rowId]="row.rowId"
                                    [eventsOnly]=true
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    >

                                    <input
                                        matInput
                                        [matDatepicker]="geboorteDatum"
                                        [magic]="mgc.geboorteDatum"
                                        [rowId]="row.rowId"
                                        [formControlName]="mgc.geboorteDatum"
                                        mgFormat
                                        />

                                    <mat-datepicker-toggle
                                        matSuffix [for]="geboorteDatum"
                                        *ngIf="mg.checkIsReadOnly(mgc.geboorteDatum) &&  mg.isRowInRowEditing(row)">
                                    </mat-datepicker-toggle>

                                    <mat-datepicker #geboorteDatum></mat-datepicker>
                                </mat-form-field>
                            </ng-container>

                            <label
                                *ngIf="!mg.isRowInRowEditing(row)"
                                [magic]="mgc.geboorteDatum"
                                [rowId]="row.rowId"
                                [style.visibility]="mg.getVisible(mgc.geboorteDatum)"
                                >
                                {{mg.getValue(mgc.geboorteDatum, row.rowId)
                                | magicDate : mgc.geboorteDatum}}
                            </label>
                        </ng-container>
                    </td>
                </ng-container>

                <!--clmGeslacht-->
                <ng-container
                    [magic]="mgc.clmGeslacht"
                    [matColumnDef]="mgc.clmGeslacht"
                    >
                    <th
                        mat-header-cell
                        *matHeaderCellDef mat-sort-header
                        >
                        {{mg.getText(mgc.clmGeslacht)}}
                    </th>

                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer"
                        >
                        <ng-container *ngIf="mg.ifRowCreated(row)">


                            <ng-container
                                [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                *ngIf="mg.isRowInRowEditing(row)"
                                >
                                <mat-form-field>
                                    <mat-select
                                        [magic]="mgc.geslacht"
                                        [rowId]="row.rowId"
                                        [formControlName]="mgc.geslacht"
                                        >
                                        <mat-option
                                            *ngFor="let o of mg.getItemListValues(mgc.geslacht, row.rowId);"
                                            [value]="o.index"
                                            >
                                            {{o.displayValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <div *ngIf="!mg.isRowInRowEditing(row)">
                                <label
                                    [magic]="mgc.geslacht"
                                    [rowId]="row.rowId"
                                    >
                                    {{ mg.getDisplayValue(mgc.geslacht, row.rowId) }}
                                </label>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Buttons -->
                <ng-container
                    [magic]="mgc.ColBTN"
                    [matColumnDef]="mgc.ColBTN">

                    <th
                        mat-header-cell
                        *matHeaderCellDef ></th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer">

                        <ng-container *ngIf="mg.ifRowCreated(row)">
                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button
                                tabindex="-1"
                                mat-button
                                matSuffix
                                mat-icon-button
                                [style.visibility]="mg.getVisible(mgc.pb_Delete, row.rowId)"
                                [magic]="mgc.pb_Delete"
                                [rowId]="row.rowId">
                                <mat-icon>delete</mat-icon> Verwijderen
                                </button>
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [magic]="mgc.SaveRowEditing"
                                [style.visibility]="mg.getVisible(mgc.SaveRowEditing, row.rowId)"
                                [rowId]="row.rowId">
                                <mat-icon>save</mat-icon> Opslaan
                                </button>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>


                <!--Buttons2-->
                <ng-container
                    [magic]="mgc.ColBTN2"
                    [matColumnDef]="mgc.ColBTN2">

                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header></th>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        magicMark="magicTableRowContainer">

                        <ng-container *ngIf="mg.ifRowCreated(row)">

                            <ng-container *ngIf="!mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [style.visibility]="mg.getVisible(mgc.EnterRowEditing, row.rowId)"
                                [magic]="mgc.EnterRowEditing"
                                [rowId]="row.rowId">
                                <mat-icon>edit</mat-icon> Wijzigen
                                </button>
                            </ng-container>

                            <ng-container *ngIf="mg.isRowInRowEditing(row)">
                                <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                [style.visibility]="mg.getVisible(mgc.CancelRowEditing, row.rowId)"
                                [magic]="mgc.CancelRowEditing"
                                [rowId]="row.rowId">
                                <mat-icon>cancel</mat-icon> Annuleren
                                </button>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    [ngClass]="{ 'selected': selection.isSelected(row)}"
                    [class.editmode]="mg.isRowInRowEditing(row)"
                    mat-row *matRowDef="let row; columns: displayedColumns"
                    [magicRow]="row?.rowId"></tr>
            </table>
        </div>

        <mat-paginator
            #paginator
            [pageSize]="30"
            (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>

    </div>
</div>
