import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./UploadFiles.mg.controls.g";


import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";


import { MagicModalInterface } from "@magic-xpa/angular";

@Component({
    selector: 'mga-UploadFiles',
    providers: [...magicProviders],
    templateUrl: './UploadFiles.component.html'
})
export class UploadFiles extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgcp = MgCustomProperties;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    fileText: string | ArrayBuffer;
    fileUpload(event) {
        const reader = new FileReader();
        reader.readAsDataURL(event.srcElement.files[0]);
        const i = event.srcElement.files[0].type.length;
        const num = 5 + i + 8;
        const me = this;
        reader.onload = function() {
            me.fileText = reader.result;
            // Updating the Magic Variable with the selected image's base64 representation
            me.mg.setValueToControl('Blob', me.fileText.slice(num));
            // Updating the FileName into the Magic Variable
            me.mg.setValueToControl('vFileName', event.srcElement.files[0].name);
        }
    }

    private static readonly formName: string = "UploadFiles";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return UploadFiles.x;
    }
    Y() {
        return UploadFiles.y;
    }
    Width(): string {
        return UploadFiles.width;
    }
    Height(): string {
        return UploadFiles.height;
    }
    IsCenteredToWindow() {
        return UploadFiles.isCenteredToWindow;
    }
    FormName() {
        return UploadFiles.formName;
    }
    ShowTitleBar() {
        return UploadFiles.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return UploadFiles.shouldCloseOnBackgroundClick;
    }
}
