import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Uitgiftepunten = "Uitgiftepunten",
        Table1 = "Table1",
        Column30 = "Column30",
        cGegevensUitgifte = "cGegevensUitgifte",
        Column8 = "Column8",
        cWijzigen = "cWijzigen",
        cOpslaan = "cOpslaan",
        cVerwijderen = "cVerwijderen",
        cAnnuleer = "cAnnuleer",
        pb_Toevoegen = "pb_Toevoegen",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column30',
        'Column8',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cGegevensUitgifte(): FormControl {
        return this.getTableChildFormControl(MgControlName.cGegevensUitgifte);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}