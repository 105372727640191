import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    OverigUitgaven = "OverigUitgaven",
        cOverigeUitgaven = "cOverigeUitgaven",
        Column9 = "Column9",
        cAflossing = "cAflossing",
        Column11 = "Column11",
        cNaamAflossing = "cNaamAflossing",
        Column13 = "Column13",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        cToevoegen = "cToevoegen",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Column9',
        'Column11',
        'Column13',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cAflossing(): FormControl {
        return this.getTableChildFormControl(MgControlName.cAflossing);
    }

    get cNaamAflossing(): FormControl {
        return this.getTableChildFormControl(MgControlName.cNaamAflossing);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}