<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.Openingstijden"
    >
        <div style="display: flex; flex-direction: row">
            <div
                [magic]="mgc.Table1"
                class="container_border"
            >
                <table>
                    <th>
                        <label>
                            Dag
                        </label>
                    </th>
                    <th>
                        <label>
                            Openingstijd
                        </label>
                    </th>
                    <th>
                        <label>
                            Sluitingstijd
                        </label>
                    </th>
                    <th>
                        <label>
                        </label>
                    </th>
                    <ng-container *ngFor="let row of task.Records.list">
                        <tr
                            *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()"
                            id="row"
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            class="table_row"
                            [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table1, row.rowId)}"
                            [class.selected]="tableService.getSelectedRow() === row.rowId"
                            (click)="tableService.selectRow(row.rowId)"
                            [magicRow]="row?.rowId"
                        >
                            <td>
                                <div>
                                    <div *ngIf="mg.isRowInRowEditing(row)">
                                        <mat-form-field>
                                            <mat-select
                                                [magic]="mgc.dag"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.dag"
                                            >
                                                <mat-option
                                                    *ngFor="let o of mg.getItemListValues(mgc.dag, row.rowId);"
                                                    [value]="o.index"
                                                >
                                                    {{o.displayValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="!mg.isRowInRowEditing(row)">
                                        <label
                                            [magic]="mgc.dag"
                                            [rowId]="row.rowId"
                                        >
                                            {{ mg.getDisplayValue(mgc.dag, row.rowId) }}
                                        </label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='time'
                                                [magic]="mgc.open_tijd"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.open_tijd"
                                            >
                                            <mgError
                                                [magic]=mgc.open_tijd
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.open_tijd"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.open_tijd, row.rowId)
| magicTime : mgc.open_tijd}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='time'
                                                [magic]="mgc.gesloten_tijd"
                                                [rowId]="row.rowId"
                                                [formControlName]="mgc.gesloten_tijd"
                                            >
                                            <mgError
                                                [magic]=mgc.gesloten_tijd
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.gesloten_tijd"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.gesloten_tijd, row.rowId)
| magicTime : mgc.gesloten_tijd}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <button
                                    mat-raised-button
                                    *ngIf="!mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.EnterRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Wijzigen
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.SaveRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Opslaan
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.CancelRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Annuleer
                                </button>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    [magic]="mgc.cToevoegen"
                                    [rowId]="row.rowId"
                                >
                                    +
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>