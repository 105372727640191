<div novalidate [formGroup]="screenFormGroup" class="screen">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="card shadow-lg o-hidden border-0 my-5">
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-flex bg-white" >
                            <img class="login-logo" src="assets/images/PNG/Logo/logo-voedselbank.png" alt="">
                            </div>
                            <div class="col-md-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h2 class="text-dark mb-4"> Welkom!</h2>
                                    </div>
                                    <div class="user">
                                        <div class="form-group">
                                            <div [magic]="mgc.Login">
                                                
                                                    <!--Email-->
                                                    <mat-form-field>
                                                        <mat-label>{{ mg.getText(mgc.Label2) }}</mat-label>
                                                        <input
                                                            matInput
                                                            [magic]="mgc.Email"
                                                            [formControlName]="mgc.Email"
                                                            mgformat
                                                            required
                                                        />
                                                        <mgError [magic]=mgc.Email> </mgError>
                                                    </mat-form-field>

                                                    <!--Wachtwoord-->
                                                    <mat-form-field>
                                                        <mat-label>{{ mg.getText(mgc.Label4) }}</mat-label>
                                                        <input
                                                            matInput
                                                            [magic]="mgc.Wachtwoord"
                                                            [formControlName]="mgc.Wachtwoord"
                                                            mgformat
                                                            required
                                                            [attr.required]=""
                                                            type="password"
                                                        />
                                                        <mgError [magic]=mgc.Wachtwoord> </mgError>
                                                    </mat-form-field>
                                                

                                                <div class="column-login">
                                                    <button type="button" [magic]="mgc.LoginButton"
                                                        [disabled]="mg.isDisabled(mgc.LoginButton )"
                                                        class="btn btn-primary"
                                                        id="btn-login">
                                                        Login
                                                    </button>  
                                                    <a href="javascript:;" [magic]="mgc.bVergeten" class="a-vergeten">
                                                        Wachtwoord vergeten?
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>