import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Aanvraagformulier = "Aanvraagformulier",
        cLoginForm = "cLoginForm",
        lVoornaam = "lVoornaam",
        cVoornaam = "cVoornaam",
        lVoornaamPartner = "lVoornaamPartner",
        cVoornaamPartner = "cVoornaamPartner",
        lNaamOrg = "lNaamOrg",
        cNaam_Org = "cNaam_Org",
        lAchternaam = "lAchternaam",
        cAchternaam = "cAchternaam",
        lAchternaamPartner = "lAchternaamPartner",
        cAchternaamPartner = "cAchternaamPartner",
        lEmailOrg = "lEmailOrg",
        cEmail_Org = "cEmail_Org",
        lGeboorteDatum_partner = "lGeboorteDatum_partner",
        cGeboorteDatum_partner = "cGeboorteDatum_partner",
        lTelefoonOrg = "lTelefoonOrg",
        cTelefoonnummer_Org = "cTelefoonnummer_Org",
        lGeboorteDatum = "lGeboorteDatum",
        cGeboorteDatum = "cGeboorteDatum",
        cGeslacht = "cGeslacht",
        cGeslachtPartner = "cGeslachtPartner",
        cEmail = "cEmail",
        lReferentienummer = "lReferentienummer",
        cReferentienummer = "cReferentienummer",
        lEmail = "lEmail",
        lContactpersoon_org = "lContactpersoon_org",
        cContactpersoon_org = "cContactpersoon_org",
        lTelefoon = "lTelefoon",
        cTelefoon = "cTelefoon",
        lInstantie = "lInstantie",
        cAdresAanvrager = "cAdresAanvrager",
        cBerekenen = "cBerekenen",
        cComboBoxUitgifte = "cComboBoxUitgifte",
        lAdres = "lAdres",
        lPostcode = "lPostcode",
        cPostcode = "cPostcode",
        cComboBoxKlant = "cComboBoxKlant",
        lPlaats = "lPlaats",
        cPlaats = "cPlaats",
        lKinderenToevoegen = "lKinderenToevoegen",
        cChildren = "cChildren",
        lInkomsten = "lInkomsten",
        lNettoLoon = "lNettoLoon",
        cNettoloon = "cNettoloon",
        lHuurHypotheek = "lHuurHypotheek",
        cHuurHypotheek = "cHuurHypotheek",
        lUiterking = "lUiterking",
        cUitkering = "cUitkering",
        lEnergieWater = "lEnergieWater",
        cEnergieWater = "cEnergieWater",
        lNettoLoonPartner = "lNettoLoonPartner",
        cNettoPartner = "cNettoPartner",
        lbl_PZV = "lbl_PZV",
        cPremieZKV = "cPremieZKV",
        lUitkeringPartner = "lUitkeringPartner",
        cUitkeringPartner = "cUitkeringPartner",
        lGemWaBelasting = "lGemWaBelasting",
        cBelastingWater = "cBelastingWater",
        lBelastingTeruggave = "lBelastingTeruggave",
        cBelastingTeruggave = "cBelastingTeruggave",
        lPersHuisUitgaven = "lPersHuisUitgaven",
        cPersHuisUitgaven = "cPersHuisUitgaven",
        lAlimentatie = "lAlimentatie",
        lHuurToeslag = "lHuurToeslag",
        lZorgToeslag = "lZorgToeslag",
        lKostgeldKind = "lKostgeldKind",
        lKindgebondenBudget = "lKindgebondenBudget",
        lBijstandBedrag = "lBijstandBedrag",
        cKGB = "cKGB",
        cKostgeldKind = "cKostgeldKind",
        cZorgToeslag = "cZorgToeslag",
        cBijstandBedrag = "cBijstandBedrag",
        cHuurToeslag = "cHuurToeslag",
        cAlimentatie = "cAlimentatie",
        cOverigeUitgaven = "cOverigeUitgaven",
        lBijstandDoel = "lBijstandDoel",
        cBijstandDoel = "cBijstandDoel",
        lTotaalInkomsten = "lTotaalInkomsten",
        totaal_inkomsten = "totaal_inkomsten",
        lTotaalUitgaven = "lTotaalUitgaven",
        totaal_uitgaven = "totaal_uitgaven",
        lNettoTeBesteden = "lNettoTeBesteden",
        netto_tebesteden = "netto_tebesteden",
        lBewindvoering = "lBewindvoering",
        Label127 = "Label127",
        lWeekgeld = "lWeekgeld",
        cWeekgeld = "cWeekgeld",
        v_FIleFound = "v_FIleFound",
        v_FileToegevoegd = "v_FileToegevoegd",
        Label139 = "Label139",
        vTotaalOverigeUitgaven = "vTotaalOverigeUitgaven",
        ShowImages = "ShowImages",
        lToelichting1 = "lToelichting1",
        cToelichting1 = "cToelichting1",
        cbClient = "cbClient",
        cbAanvrager = "cbAanvrager",
        cbInwoner = "cbInwoner",
        cVerzenden = "cVerzenden",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cVoornaam(): FormControl {
        return this.fg.controls[MgControlName.cVoornaam] as FormControl;
    }

    get cVoornaamPartner(): FormControl {
        return this.fg.controls[MgControlName.cVoornaamPartner] as FormControl;
    }

    get cNaam_Org(): FormControl {
        return this.fg.controls[MgControlName.cNaam_Org] as FormControl;
    }

    get cAchternaam(): FormControl {
        return this.fg.controls[MgControlName.cAchternaam] as FormControl;
    }

    get cAchternaamPartner(): FormControl {
        return this.fg.controls[MgControlName.cAchternaamPartner] as FormControl;
    }

    get cEmail_Org(): FormControl {
        return this.fg.controls[MgControlName.cEmail_Org] as FormControl;
    }

    get cGeboorteDatum_partner(): FormControl {
        return this.fg.controls[MgControlName.cGeboorteDatum_partner] as FormControl;
    }

    get cTelefoonnummer_Org(): FormControl {
        return this.fg.controls[MgControlName.cTelefoonnummer_Org] as FormControl;
    }

    get cGeboorteDatum(): FormControl {
        return this.fg.controls[MgControlName.cGeboorteDatum] as FormControl;
    }

    get cGeslacht(): FormControl {
        return this.fg.controls[MgControlName.cGeslacht] as FormControl;
    }

    get cGeslachtPartner(): FormControl {
        return this.fg.controls[MgControlName.cGeslachtPartner] as FormControl;
    }

    get cEmail(): FormControl {
        return this.fg.controls[MgControlName.cEmail] as FormControl;
    }

    get cReferentienummer(): FormControl {
        return this.fg.controls[MgControlName.cReferentienummer] as FormControl;
    }

    get cContactpersoon_org(): FormControl {
        return this.fg.controls[MgControlName.cContactpersoon_org] as FormControl;
    }

    get cTelefoon(): FormControl {
        return this.fg.controls[MgControlName.cTelefoon] as FormControl;
    }

    get cAdresAanvrager(): FormControl {
        return this.fg.controls[MgControlName.cAdresAanvrager] as FormControl;
    }

    get cComboBoxUitgifte(): FormControl {
        return this.fg.controls[MgControlName.cComboBoxUitgifte] as FormControl;
    }

    get cPostcode(): FormControl {
        return this.fg.controls[MgControlName.cPostcode] as FormControl;
    }

    get cComboBoxKlant(): FormControl {
        return this.fg.controls[MgControlName.cComboBoxKlant] as FormControl;
    }

    get cPlaats(): FormControl {
        return this.fg.controls[MgControlName.cPlaats] as FormControl;
    }

    get cNettoloon(): FormControl {
        return this.fg.controls[MgControlName.cNettoloon] as FormControl;
    }

    get cHuurHypotheek(): FormControl {
        return this.fg.controls[MgControlName.cHuurHypotheek] as FormControl;
    }

    get cUitkering(): FormControl {
        return this.fg.controls[MgControlName.cUitkering] as FormControl;
    }

    get cEnergieWater(): FormControl {
        return this.fg.controls[MgControlName.cEnergieWater] as FormControl;
    }

    get cNettoPartner(): FormControl {
        return this.fg.controls[MgControlName.cNettoPartner] as FormControl;
    }

    get cPremieZKV(): FormControl {
        return this.fg.controls[MgControlName.cPremieZKV] as FormControl;
    }

    get cUitkeringPartner(): FormControl {
        return this.fg.controls[MgControlName.cUitkeringPartner] as FormControl;
    }

    get cBelastingWater(): FormControl {
        return this.fg.controls[MgControlName.cBelastingWater] as FormControl;
    }

    get cBelastingTeruggave(): FormControl {
        return this.fg.controls[MgControlName.cBelastingTeruggave] as FormControl;
    }

    get cPersHuisUitgaven(): FormControl {
        return this.fg.controls[MgControlName.cPersHuisUitgaven] as FormControl;
    }

    get cKGB(): FormControl {
        return this.fg.controls[MgControlName.cKGB] as FormControl;
    }

    get cKostgeldKind(): FormControl {
        return this.fg.controls[MgControlName.cKostgeldKind] as FormControl;
    }

    get cZorgToeslag(): FormControl {
        return this.fg.controls[MgControlName.cZorgToeslag] as FormControl;
    }

    get cBijstandBedrag(): FormControl {
        return this.fg.controls[MgControlName.cBijstandBedrag] as FormControl;
    }

    get cHuurToeslag(): FormControl {
        return this.fg.controls[MgControlName.cHuurToeslag] as FormControl;
    }

    get cAlimentatie(): FormControl {
        return this.fg.controls[MgControlName.cAlimentatie] as FormControl;
    }

    get cBijstandDoel(): FormControl {
        return this.fg.controls[MgControlName.cBijstandDoel] as FormControl;
    }

    get totaal_inkomsten(): FormControl {
        return this.fg.controls[MgControlName.totaal_inkomsten] as FormControl;
    }

    get totaal_uitgaven(): FormControl {
        return this.fg.controls[MgControlName.totaal_uitgaven] as FormControl;
    }

    get netto_tebesteden(): FormControl {
        return this.fg.controls[MgControlName.netto_tebesteden] as FormControl;
    }

    get cWeekgeld(): FormControl {
        return this.fg.controls[MgControlName.cWeekgeld] as FormControl;
    }

    get v_FIleFound(): FormControl {
        return this.fg.controls[MgControlName.v_FIleFound] as FormControl;
    }

    get v_FileToegevoegd(): FormControl {
        return this.fg.controls[MgControlName.v_FileToegevoegd] as FormControl;
    }

    get vTotaalOverigeUitgaven(): FormControl {
        return this.fg.controls[MgControlName.vTotaalOverigeUitgaven] as FormControl;
    }

    get cToelichting1(): FormControl {
        return this.fg.controls[MgControlName.cToelichting1] as FormControl;
    }

    get cbClient(): FormControl {
        return this.fg.controls[MgControlName.cbClient] as FormControl;
    }

    get cbAanvrager(): FormControl {
        return this.fg.controls[MgControlName.cbAanvrager] as FormControl;
    }

    get cbInwoner(): FormControl {
        return this.fg.controls[MgControlName.cbInwoner] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}