import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Aanvragen = "Aanvragen",
        Table1 = "Table1",
        Col_Status = "Col_Status",
        cStatus = "cStatus",
        clm_AanvraagDatum = "clm_AanvraagDatum",
        Clients_AanvraagDatum = "Clients_AanvraagDatum",
        cColumReferentienr = "cColumReferentienr",
        referentienummer = "referentienummer",
        Col_Achternaam = "Col_Achternaam",
        achternaam = "achternaam",
        cColumGeboortedatum = "cColumGeboortedatum",
        geboorteDatum = "geboorteDatum",
        Col_BTN = "Col_BTN",
        cMuteren = "cMuteren",
        pb_Delete = "pb_Delete",
}
export enum MgCustomProperties {}
export var
    MgDisplayedColumns = [
        'Col_Status',
        'clm_AanvraagDatum',
        'cColumReferentienr',
        'Col_Achternaam',
        'cColumGeboortedatum',
        'Col_BTN',
    ];

export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cStatus(): FormControl {
        return this.getTableChildFormControl(MgControlName.cStatus);
    }

    get Clients_AanvraagDatum(): FormControl {
        return this.getTableChildFormControl(MgControlName.Clients_AanvraagDatum);
    }

    get referentienummer(): FormControl {
        return this.getTableChildFormControl(MgControlName.referentienummer);
    }

    get achternaam(): FormControl {
        return this.getTableChildFormControl(MgControlName.achternaam);
    }

    get geboorteDatum(): FormControl {
        return this.getTableChildFormControl(MgControlName.geboorteDatum);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}