<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        [magic]="mgc.ChangePWD"
    >
        <div class="col-12 mt-4">

            <!--lOudWW-->
            <mat-form-field>
                <mat-label>{{ mg.getText(mgc.lOudWW) }}</mat-label>
                <input
                        matInput
                        [magic]="mgc.cPWDOLD"
                        [attr.required]=""
                        type="password"
                        [formControlName]="mgc.cPWDOLD"
                        mgFormat
                        required
                    >
                <mgError [magic]=mgc.cPWDOLD> </mgError>
            </mat-form-field>

            <!--lOudWW-->
            <mat-form-field>
                <mat-label>{{ mg.getText(mgc.lWW1) }}</mat-label>
                <input
                        matInput
                        [magic]="mgc.cPWD1"
                        [attr.required]=""
                        type="password"
                        [formControlName]="mgc.cPWD1"
                        mgFormat
                        required
                    >
                    <mgError [magic]=mgc.cPWD1> </mgError>
            </mat-form-field>

            <!--lWW2-->
            <mat-form-field>
                <mat-label>{{ mg.getText(mgc.lWW2) }}</mat-label>
                <input
                        matInput
                        [magic]="mgc.cPWD2"
                        [attr.required]=""
                        type="password"
                        [formControlName]="mgc.cPWD2"
                        mgFormat
                        required
                    >
                    <mgError [magic]=mgc.cPWD2> </mgError>
            </mat-form-field>

        </div>

        <button class="ml-3"
            mat-raised-button
            color="primary"
            [magic]="mgc.cChangePWD"
            [disabled]="mg.isDisabled(mgc.cChangePWD )">
            <mat-icon>save</mat-icon>
        </button>

    </div>
</div>