<div
  novalidate
  [formGroup]="screenFormGroup"
>
  <div
    [magic]="mgc.DownloadDocumentenClient"
  >

    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
    <button
      class="mt-2 mb-2 ml-2"
      mat-raised-button
      color="primary"
      [magic]="mgc.btn_UploadDocument"
      [style.visibility]="mg.getVisible(mgc.btn_UploadDocument)"
      (change)="fileUpload($event)">
      <label class="pt-3 pb-3 pl-2 pr-2 uploadbtn" style="display:inline;">
        <i class="fa fa-folder-open"></i> Upload Document
        <input
          type="file"
          style="display: none"
        />
      </label>
    </button>
        </div>
        <div class="col-6">
          <button
              class="mt-2 mb-2 ml-2"
              mat-raised-button
              color="primary"
              [magic]="mgc.pb_Save">
            <i class="fa-solid fa-floppy-disk"></i> Opslaan
          </button>
        </div>
      </div>
    </div>


    <div class="example-container mat-elevation-z8 Table2TableContainerProps">
      <mat-table
        #table
        [dataSource]="dataSource"
        class="container_border"
        matSort
        matSortDisableClear
        (matSortChange)="tableService.sortData($event)"
      >
        <ng-container
          [magic]="mgc.cCFileName"
          [matColumnDef]="mgc.cCFileName"
        >
          <mat-header-cell *matHeaderCellDef>
            Bestandsnaam
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            magicMark="magicTableRowContainer"
          >
            <div
              *ngIf="mg.ifRowCreated(row)"
            >
              <div
                [formGroup]="mg.getFormGroupByRow(row.rowId)"
              >
                <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                  <div>
                    <input
                      *ngIf="mg.isRowInRowEditing(row)"
                      matInput
                      [magic]="mgc.FileName"
                      [rowId]="row.rowId"
                      [formControlName]="mgc.FileName"
                      mgFormat
                    >
                    <mgError
                      [magic]=mgc.FileName
                      [rowId]="row.rowId"
                    ></mgError>
                  </div>
                </mat-form-field>
                <label
                  *ngIf="!mg.isRowInRowEditing(row)"
                  [magic]="mgc.FileName"
                  [rowId]="row.rowId"
                >
                  {{mg.getValue(mgc.FileName, row.rowId)
                  }}
                </label>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container
          [magic]="mgc.Column67108886"
          [matColumnDef]="mgc.Column67108886"
        >
          <mat-header-cell *matHeaderCellDef>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            magicMark="magicTableRowContainer"
          >
            <div
              *ngIf="mg.ifRowCreated(row)"
            >
              <button
                mat-raised-button
                color="primary"
                [magic]="mgc.btn_ShowDocument"
                [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.btn_ShowDocument, row.rowId)"
              >
                Download Document
              </button>
            </div>
            <div
              *ngIf="mg.ifRowCreated(row)"
            >
              <button
                mat-raised-button
                color="primary"
                [magic]="mgc.pb_Delete"
                [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.pb_Delete, row.rowId)"
                ngbTooltip="Verwijderen"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns;"
          class="table_row"
          [ngClass]="{ 'selected': selection.isSelected(row)}"
          [magicRow]="row?.rowId"
          (click)="row? tableService.selectRow(row.rowId) : null"
        >
        </mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="tableService.mgOnPaginateChange($event)"
      >
      </mat-paginator>
    </div>
    <div class="HiddenFromUser">
      <label
        [magic]="mgc.FNL"
        class="lable_overflow mgFont1"
      >
        vFileNameLabel
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            [magic]="mgc.vFileName"
            [formControlName]="mgc.vFileName"
            mgFormat
          >
          <mgError [magic]=mgc.vFileName></mgError>
        </div>
      </mat-form-field>

      <mat-form-field>
        <div>
          <input
            matInput
            [magic]="mgc.Blob"
            [formControlName]="mgc.Blob"
          >
          <mgError [magic]=mgc.Blob></mgError>
        </div>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        [magic]="mgc.pb_HiddenSave"
      >
        {{mg.getValue(mgc.pb_HiddenSave)}}
      </button>
    </div>
  </div>
</div>
